// initial diagram with main contention and nothing else
import { v4 as uuid } from "uuid";
import {
  defaultNodeWidth,
  defaultNodeHeight,
  defaultTextareaRows,
  originX,
  originY,
  defaultTextareaCols,
} from "../mapUtils/mapConstants";


const InitialNodes = () => {
  const main_contention_uid = uuid();
  return [
    {
      id: main_contention_uid,
      type: "editableText",
      width: defaultNodeWidth,
      height: defaultNodeHeight,
      position: { x: originX, y: originY },
      numDsWODs: 1,
      data: {
        number: '1.1',
        argType: "main-contention",
        nodeType: "editableText",
        id: main_contention_uid,
        text: "",
        rows: defaultTextareaRows,
        cols: defaultTextareaCols,
      },
      selected: true,
      draggable: false
    },
  ]
} 

const InitialEdges = [];

export { InitialNodes, InitialEdges };

import Button from "react-bootstrap/Button";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useCallback, useState } from "react";
import "../../index.css"

import { BsNodeMinus, BsNodePlus } from "react-icons/bs"
import { AiOutlineInteraction, AiOutlineUndo, AiOutlineRedo, AiOutlineDelete, AiOutlineQuestionCircle, AiOutlineCloseCircle } from "react-icons/ai"
import { useStore } from "../../utils/store";
import { Help } from '../subcomponents/Help';

import {Toolbar} from 'react-aria-components';

const iconSize = 22
const nodeButtonVariant = "light"

function ActionBar({ addChildNode, map, deleteCurrentNode, toggleCurrentNode }) {
  
  const { undo, redo } = map
  const [showHelp, setShowHelp] = useState(false)
  const openModal = useStore(s=>s.openModal)
  
  return (
    // <div 
    //   className="action-bar"
    //   aria-label="action toolbar"
    //   aria-role="toolbar"
    // >
    <Toolbar className="action-bar">
      <ActionBarButton
        variant={nodeButtonVariant}
        onClick={() => {
          addChildNode("editableText", "reason");
        }}
        title="add reason"
        rotate
        tabIndex={openModal ? -1 : 0}
      >
        <BsNodePlus
          aria-hidden="true"
          size={iconSize}
          style={{ transform: "rotate(90deg)" }}
          color="green"
        />
      </ActionBarButton>
      <ActionBarButton
        variant={nodeButtonVariant}
        onClick={async () => {
          addChildNode("editableText", "objection");
        }}
        title="add objection"
        tabIndex={openModal ? -1 : 0}
      >
        <BsNodeMinus
          aria-hidden="true"
          size={iconSize}
          style={{ transform: "rotate(90deg)" }}
          color="red"
        />
      </ActionBarButton>
      <ActionBarButton
        variant={nodeButtonVariant}
        onClick={() => {
          addChildNode("editableText", "copremise");
        }}
        title="add co-premise"
        tabIndex={openModal ? -1 : 0}
      >
        <BsNodePlus size={iconSize} aria-hidden="true"/>
      </ActionBarButton>
      <ActionBarButton
        variant={nodeButtonVariant}
        onClick={() => {
          toggleCurrentNode();
        }}
        title="toggle reason / objection"
        tabIndex={openModal ? -1 : 0}
      >
        <AiOutlineInteraction size={iconSize} aria-hidden="true"/>
      </ActionBarButton>
      <ActionBarButton
        variant={nodeButtonVariant}
        title="delete node"
        onClick={deleteCurrentNode}
        tabIndex={openModal ? -1 : 0}
      >
        <AiOutlineDelete size={iconSize} aria-hidden="true"/>
      </ActionBarButton>
      <ActionBarButton
        variant={nodeButtonVariant}
        title="undo"
        onClick={undo}
        tabIndex={openModal ? -1 : 0}
      >
        <AiOutlineUndo size={iconSize} aria-hidden="true"/>
      </ActionBarButton>
      <ActionBarButton
        variant={nodeButtonVariant}
        title="redo"
        onClick={redo}
        tabIndex={openModal ? -1 : 0}
      >
        <AiOutlineRedo size={iconSize} aria-hidden="true"/>
      </ActionBarButton>
      <ActionBarButton 
        variant="info" 
        title="Key Commands"
        onClick={()=>setShowHelp((showHelp)=>!showHelp)}
        id="help-button"
        tabIndex={openModal ? -1 : 0}
        expanded = {showHelp ? true : false}
      >
        {
          showHelp ? 
            <AiOutlineCloseCircle color="black" size={iconSize} id="help-button-close" aria-hidden="true"/> : 
            <AiOutlineQuestionCircle color="black" size={iconSize} id="help-button-open" aria-hidden="true"/>
        }
      </ActionBarButton>
      <Help setShowHelp={setShowHelp} showHelp={showHelp}/>
    </Toolbar>
  );
}

const ActionBarButton = props => {
  const [hide, setHide] = useState(false)
  const onKeyPress = useCallback(e=>{
    if(e.key == "Escape"){
      setHide(true)
    }
  }, [hide, setHide])
  const handleShow = useCallback(()=>{
    document.addEventListener("keydown", onKeyPress)
  }, [onKeyPress])
  const handleHide = useCallback(()=>{
    setHide(false)
    document.removeEventListener("keydown", onKeyPress)
  }, [onKeyPress, setHide])
  return(
    <Tippy 
      content={props.title} 
      placement="bottom" 
      delay={[500, 0]}
      onShow={handleShow}
      onHidden={handleHide}
      disabled={hide}
    >
        <Button
          id={props.id}
          variant={props.variant}
          onClick={props.onClick}
          style={{ marginLeft: 2, marginRight: 2, ...props.style }}
          size={props.size}
          tabIndex={props.tabIndex}
          aria-label={props.title}
          role="button"
          aria-expanded = {props.expanded == true ? "true" : props.expanded == false ? "false" : undefined}
        >
          {props.children}
        </Button>
    </Tippy>
  )
}

export default ActionBar;

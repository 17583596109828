
import {useCallback, useEffect, useRef, useState} from 'react'
import Button from "react-bootstrap/Button";
import { AiOutlineForm, AiOutlineCloseCircle } from "react-icons/ai"
import ContentEditable from 'react-contenteditable'
import { useStore } from '../../utils/store';
import Modal from 'react-bootstrap/Modal';

function Scratchpad({auth, map}) {
  const {notes, notesOpen, setNotesOpen} = map
  const ref = useRef()

  const openModal = useStore(s=>s.openModal)

  useEffect(()=>{
    if(!notesOpen){
      return
    }
    console.log("focus notes")
    if(ref.current){
      ref.current.getEl().focus()
      ref.current.getEl().tabIndex = openModal == null ? 0 : -1
    }
  },[notesOpen, openModal])

  return (
    <div>
      <div className={notesOpen ? "scratchpad-icon scratchpad-icon-open" : "scratchpad-icon"} data-norender>
        <Button
            variant="outline-light"
            onClick={()=>setNotesOpen(!notesOpen)}
            size="sm"
            aria-label= {notesOpen ? "close scratchpad" : "open scratchpad"}
            tabIndex={openModal == null ? 0 : -1}
            style={{zIndex:100}}
        >
          {
            notesOpen ? <AiOutlineCloseCircle size={14} color="black" aria-hidden/> : <AiOutlineForm size={14} color="black" aria-hidden/>
          }
        </Button>
      </div>
        <ContentEditable
          ref={ref}
          style={{display: notesOpen?'block':'none'}}
          className='scratch-pad'
          html={notes.current || "notes"}
          onChange={e=>notes.current = e.target.value}
          aria-label='notes'
        />
    </div>
  );
}

// function Scratchpad({auth, map}) {
//   const {notes, notesOpen, setNotesOpen} = map

//   return (
//     <>
//       <div className="scratchpad-icon" data-norender>
//         <Button
//             variant="outline-light"
//             onClick={()=>setNotesOpen(!notesOpen)}
//             size="sm"
//             aria-label = "open scratchpad"
//             tabIndex={0}
//         >
//           {
//             notesOpen || <AiOutlineForm size={14} color="black"/>
//           }
//         </Button>
//       </div>
//       <Modal 
//         enforceFocus={false}
//         bsPrefix='notes-modal' 
//         show={notesOpen} 
//         onHide={()=>{
//           console.log("!!")
//           setNotesOpen(false)
//         }}
//         scrollable
//       >
//         <Modal.Header closeButton>
//           <div className='modal-title' style={{marginRight:"auto", marginLeft:0, fontSize:16}}>
//             Scratch Pad
//           </div>
//         </Modal.Header>
//         <Modal.Body>
//           <ContentEditable
//             // style={{display: notesOpen?'block':'none'}}
//             className='scratch-pad'
//             html={notes.current}
//             onChange={e=>notes.current = e.target.value}
//           />
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }

export default Scratchpad;

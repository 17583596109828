import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./utils/firebase"
import EditMap from "./components/routes/EditMap";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth } from "./utils/firebase";
import { useMap } from "./utils/firebase"
import { ReactFlowProvider } from 'react-flow-renderer';
import { Plans } from './components/routes/Plans'
import { Privacy } from './components/routes/Privacy'
import { Terms } from "./components/routes/Terms";
import { Accessibility } from "./components/routes/Accessibility";

const App = () => 
  <React.StrictMode>
    <ReactFlowProvider>
      <BrowserRouter>
        <Page/>
      </BrowserRouter>
    </ReactFlowProvider>
  </React.StrictMode>

const Page = () => {
  const auth = useAuth()
  const map = useMap()
  return(
    <Routes>
      <Route path="/success.html" element={<EditMap map={map} auth={auth} success/>} />
      <Route path="/canceled.html" element={<EditMap map={map} auth={auth}/>} />
      <Route path="/plans.html" element={<Plans/>} />
      <Route path="/privacy.html" element={<Privacy/>} />
      <Route path="/terms-and-conditions.html" element={<Terms/>} />
      <Route path="/accessibility-statement.html" element={<Accessibility/>} />
      <Route path="/" element={<EditMap map={map} auth={auth}/>} />
    </Routes>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App/>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

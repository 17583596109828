import { getBezierPath } from "react-flow-renderer";

function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
}) {
  // console.log(data)
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeClassName = "argumentation-edge " + data.argType + "-edge" + (data.highlight ? " edge-select" : "") ;

  return (
    <path
      id={id}
      d={edgePath}
      markerEnd={markerEnd}
      className={edgeClassName}
    />
  );
}

export default CustomEdge;

import { httpsCallable } from "firebase/functions";
import { functions } from "../utils/firebase"
import { db } from "../utils/firebase";
import { useStore } from "./store";
import { alert } from "../components/subcomponents/Alert";

export const INSTITUTIONAL          = "prod_NglekF6pkUWWVl"
export const INDIVIDUAL_CARD_FREE   = "card_free_subscription"
export const INDIVIDUAL_MONTHLY     = "prod_NnTUt91gorZ6Er"
export const INDIVIDUAL_BIANNUAL    = "prod_NnTKxAsaEJBnHu"
// export const INDIVIDUAL = "prod_Nglcf7qhIvk52v"


export const subscribe = async () => {
    console.log("subscribe")
    // const startCheckoutSession = httpsCallable(functions, "startCheckoutSession")
    // startCheckoutSession({priceId: "price_1MkwhNKs0hRFBKxFPvRaWN6n"})
    const res = await fetch(
        // "https://us-central1-argumentation-44b42.cloudfunctions.net/startCheckoutSession",
        "/startCheckoutSession",
        {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                priceId: "price_1MkwhNKs0hRFBKxFPvRaWN6n",
            })
        }
    )
    const body = await res.json()
    window.location.href = body.url
}

export const handleSubscriptionSuccess = async session_id => {
    console.log("session id" + session_id)
    const res = await fetch(
        "/getCheckoutDetails",
        {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({session_id: session_id})
        }
    )
    const body = await res.json()
    console.log(body)
}

export const redeemCode = async code => {
    try{
        const { userId } = useStore.getState();
        const res = await fetch(
            "/redeemCode",
            {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({code, userId})
            }
        )
        const json = await res.json()
        console.log(json)
        return json
    } catch (error){
        console.log(error)
        return {error}
    }
}

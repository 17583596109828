import { Handle, Position, useReactFlow } from "react-flow-renderer";
import { useState, useEffect, useRef } from "react";

const lineHeight = 20

const argTypesWithoutInputHandle = ["reason", "objection", "main-contention"]

const offscreen = function(el) {
  var rect = el.getBoundingClientRect();
  return (
        (rect.x + rect.width) < 0 
    ||  (rect.y + rect.height) < 0
    ||  ( rect.x > window.innerWidth || rect.y > window.innerHeight )
  )
};

const EditableTextNode = (map) => ({ data, selected }) => {

  const [rows, setRows] = useState(1);
  const [text, setText] = useState(data.text);
  const [char, setChar] = useState(false)
  const [shrink, setShrink] = useState(false)

  const ref = useRef()
  const innerRef = useRef()
  const textInputRef = useRef()
  const shadowRef = useRef()

  const flow = useReactFlow()

  // give these refs back to the data so they can be programatically focussed by keypress
  useEffect(() => {
    data.ref = ref
    data.textInputRef = textInputRef
  }, [])

  // deterine if the inferences should be shrunk
  useEffect(() => {
    if(data.argType.includes("inference")){
      if (data.text.length) {
        setShrink(false)
      } else {
        if (textInputRef.current && (document.activeElement == textInputRef.current)) {
          // its focused, expand it
          setShrink(false)
        } else {
          setShrink(true)
        }
      }
    }
  }, [data, data.text, textInputRef.current, document.activeElement])

  useEffect(() => {
    setText(data.text)
  }, [data.text])

  // measuring the text to design the textarea
  useEffect(() => {
    let numRows = 1
    if (shadowRef.current) {
      const h = shadowRef.current.offsetHeight
      numRows = h / lineHeight
      numRows && setRows(numRows)
      data.height = h || lineHeight // no text makes h == 0, so default to 1 line
    }

    data.rows = numRows;
    map.setShouldLayout(true)

  }, [data.text, char])

  // new nodes need to get focus immidiately
  useEffect(()=>{
    if(data.isNewNode){
      data.isNewNode = false
      // flow.fitView({nodes:[{id:data.id}]})

      // if the node is offscreen, focussing it will break react-flow so we must center it as smoothly as possible
      if(offscreen(ref.current)){
        const {x, y} = ref.current.getBoundingClientRect() // get the dom coordinates
        const zoom = flow.getZoom() // capture the current zoom
        let res = flow.project({x, y}) // translate it to the react-flow coordinate system
        setTimeout(()=>{
          // delay this so its had a chance to settle
          flow.setCenter(res.x, res.y, {zoom})
        }, 100)
        setTimeout(()=>{
          // delay this so more its had a chance to settle
          textInputRef.current.focus()
        }, 200)
      } else {
        textInputRef.current.focus()
      }
    }
  },[data.isNewNode])
  
  const numbering = `${data.rank}.${data.order + 1}${data.position != undefined ? ("." + (data.position + 1)) : ""}`
  const ariaName = numbering + " " + (data.argType.includes("inference") ? "inference" : data.argType)
  // const isDraggableNode = (data.argType.includes("inference") || data.argType == "main-contention") == false

  return (
    <div
      id={data.id}
      ref={ref}
      key={data.id}
      className={`node`}
      aria-label={ariaName}
      // aria-describedby={data.dropable == undefined ? null : data.dropable == true ? "dropable" : "non-dropable"}
      tabIndex={-1}
    >
      <div
        ref={shadowRef}
        className={`${data.argType == "main-contention" ? "shadow-main-contention" : "shadow-textarea"}`}
        aria-hidden
        tabIndex={-1}
      >
        {text[text.length - 1] == "\n" ? text + " " : text}
      </div>
      <div
        className={`${data.argType} node ${shrink ? "shrink" : ""} ${data.target ? "target" : ""} ${selected ? "selected" : ""}`}
        ref={innerRef}
        tabIndex={-1}
      >
        <Handle 
          type="target" 
          position={Position.Top}
          style={{display: argTypesWithoutInputHandle.includes(data.argType) ? "none" : "block"}}
        />
        <label
          className={`${data.argType == "main-contention" ? "editable-text-label-main-contention" : data.argType.includes("inference") ? "editable-text-label-inference" : "editable-text-label"} ${shrink ? "shrink" : ""}`}
          htmlFor={data.id}
        >
          {numbering + " " + (shrink ? " " : data.argType)}
        </label>
        <textarea
          id={data.id}
          tabIndex={-1}
          // placeholder={data.argType.includes("inference") ? "inference" : data.argType.replace("-", " ")}
          aria-label={ariaName}
          ref={textInputRef}
          className={`${data.argType.includes("inference") ? "editable-text-textarea-inference" : "editable-text-textarea"} ${shrink ? "shrink" : ""}`}
          cols={data.cols}
          rows={rows}
          name="text"
          onKeyDown={e => setChar(e.target.value)}
          onChange={e => {
            console.log("comit change")
            map.setNodes(nodes => nodes.map(node => node.id != data.id ? node : ({
              ...node,
              data: {
                ...data,
                text: e.target.value
              }
            })))
            setText(e.target.value)
            map.setShouldCache(true)
          }}
          spellCheck={false}
          value={text}
          wrap="hard"
          onFocus={e => {
            map.setNodes(nodes => nodes.map(node => node.id != data.id ? node : ({
              ...node,
              draggable: false
            })))
          }}
          onBlur={e => {
            if (!(data.argType === "inference-objection" || data.argType === "inference-reason" || data.argType === "main-contention")) {
              map.setNodes(nodes => nodes.map(node => node.id != data.id ? node : ({
                ...node,
                draggable: true
              })))
            }
          }}
        />
        <Handle type="source" position={Position.Bottom}></Handle>
      </div>
    </div>
  );
}

export default EditableTextNode;

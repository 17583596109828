import { initializeApp } from "firebase/app";
import { OAuthProvider, signInWithPopup, getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, send, sendPasswordResetEmail, updateEmail } from "firebase/auth";
import { getFirestore, getDoc, getDocs, onSnapshot, setDoc, updateDoc, doc, addDoc, collection, deleteDoc, query, where, Firestore } from "firebase/firestore"; 
import { getFunctions } from 'firebase/functions';
import { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { alert, confirm, prompt } from "../components/subcomponents/Alert";
import { INDIVIDUAL_CARD_FREE } from "./subscription";

import { InitialNodes, InitialEdges } from "../templates/initialDiagram"
import { v4 as uuid } from "uuid";

import { handleSubscriptionSuccess } from "./subscription";

import { useStore } from "../utils/store";

import {INSTITUTIONAL, INDIVIDUAL} from './subscription'

const firebaseConfig = {
    apiKey: "AIzaSyD6-0TmBc2jAoypSuQCQTyGc8Y37y-Us-E",
    authDomain: "argumentation-44b42.firebaseapp.com",
    projectId: "argumentation-44b42",
    storageBucket: "argumentation-44b42.appspot.com",
    messagingSenderId: "1065335471322",
    appId: "1:1065335471322:web:2bd0404437404c159ef9e3",
    measurementId: "G-XMG48Z0B1W"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const functions = getFunctions(app);
const provider = new OAuthProvider('microsoft.com');
export const auth = getAuth();

export const loginMicrosoft = async() => {
    const result = await signInWithPopup(auth, provider)
    .catch((error) => {
        console.log({error})
        return false
    });
    if(result){
        // const credential = OAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;
        // const idToken = credential.idToken;
        // console.log({credential})
        // console.log({accessToken, idToken})
        return true
    } else {
        return false
    }

    // .then((result) => {
      // User is signed in.
      // IdP data available in result.additionalUserInfo.profile.
  
      // Get the OAuth access token and ID Token
    //   const credential = OAuthProvider.credentialFromResult(result);
    //   const accessToken = credential.accessToken;
    //   const idToken = credential.idToken;
    //   console.log("success")
    //   console.log({accessToken, idToken})
        return true
    // })
    .catch((error) => {
        console.log({error})
        return false
    });
}

export const loginEmail = async ({email, password}) => {
    let error
    const { user } = await signInWithEmailAndPassword(auth, email, password)
    .catch(e=>error = e)
    return { user, error }
}

export const createAccountEmail = async ({email, password}) => {
    let user, error
    const res = await createUserWithEmailAndPassword(auth, email, password).catch(e=>{
        console.log(e.message)
        error = e
    })
    if(res){
        user = res.user
        addUserToDb(user)
    }
    return { error, user }
}

export const logout = async ({ map, auth: user }) => {
    await signOut(auth)
    .catch(e=>console.error(e))
    if(map){
        const { nodes, setNodes, edges, setEdges, mapId, setMapId, name, setName } = map
        setNodes(InitialNodes())
        setEdges(InitialEdges)
        setMapId(undefined)
        setName("")
    }
    user.setMaps([])
    setTimeout(()=>alert("Logged out."),100)
}

export const resetPassword = async ({email}) => {
    const user = getAuth()
    sendPasswordResetEmail(user, email)
    .then(()=>{
        alert("Password reset link was sent to your email address.")
    })
    .catch(e=>alert(e.message))
}

export const forgotPassword = async () => {
    const email = await prompt("What is your email address?")
    if(!email) return;

    const user = getAuth()
    // user.sendPasswordResetEmail(email)
    sendPasswordResetEmail(user, email)
    .then(()=>{
        alert("Password reset link was sent to your email address.")
    })
    .catch(e=>alert(e.message))
}

export const changeEmail = async ({auth, setEmail}) => {
    const email = await prompt("What is your new email address?")
    if(!email) return;

    const user = getAuth()
    updateEmail(user.currentUser, email)
    .then(()=>{
        auth.setEmail(email)
        setEmail(email)
    
        const ref = doc(db, "users", auth.uid)
        updateDoc(ref, {
            email: email,
        })
    
        alert("Email successfully updated")
    })
    .catch(e=>alert(e.message))
}

export const updateUsersList = async (users) => {
    const userId = useStore.getState().userId

    const subscriptionsRef = collection(db, "subscriptions")
    const q_subscriber = query(subscriptionsRef, where("userId", "==", userId))
    const q_subscriberSnapshot = await getDocs(q_subscriber);

    let accounts = []

    q_subscriberSnapshot.forEach((doc) => {
        accounts.push(doc.id)
    });

    if(accounts.length > 1) alert(`There is a problem with your account, please contact support (multiple subscriptions for ${userId})`)
    if(accounts.length < 1) alert(`There is a problem with your account, please contact support (no subscription for ${userId})`)

    const ref = doc(db, "subscriptions", accounts[0])
    updateDoc(ref, {
        members: users
    })

}

export const useAuth = () => {
    const [uid, setUid] = useState("")
    const [email, setEmail] = useState("")
    const [maps, setMaps] = useState([])
    const [users, setUsers] = useState([])

    const setUserId = useStore(s=>s.setUserId)
    const setUserEmail = useStore(s=>s.setUserEmail)
    const setSubscription = useStore(s=>s.setSubscription)
    const setCardFreeSubscription = useStore(s=>s.setCardFreeSubscription)
    const setMembership = useStore(s=>s.setMembership)
    const resetMemberships = useStore(s=>s.resetMemberships)

    useEffect(()=>{
        let listeners = []
        const getUserMaps = async uid => {
            const userRef = doc(db, "users", uid)
            const listener = onSnapshot(userRef, user => {
                if(user.exists()){
                    const data = user.data()
                    setMaps(data.maps)
                }
            })
            listeners.push(listener)
        }
        const getUserAccount = async ({uid, email}) => {
            const subscriptionsRef = collection(db, "subscriptions")
            
            const q_subscriber = query(subscriptionsRef, 
                where("userId", "==", uid),
                where("product", "!=", INDIVIDUAL_CARD_FREE),
                )
            const q_cardFreeSubscription = query(subscriptionsRef, 
                where("userId", "==", uid),
                where("product", "==", INDIVIDUAL_CARD_FREE),
            )
            const q_member = query(subscriptionsRef, 
                where("members", "array-contains", email)
            )

            const q_subscriberSnapshot = await getDocs(q_subscriber);
            const q_cardFreeSnapshot = await getDocs(q_cardFreeSubscription)
            const q_memberSnapshot = await getDocs(q_member);

            let subscriptions = []
            let cardFreeSubscriptions = []
            let memberships = []

            // check if the user has any paid subscriptions
            q_subscriberSnapshot.forEach((doc) => subscriptions.push(doc.id));

            // check if the user has any card-free subscriptions
            q_cardFreeSnapshot.forEach((doc) =>cardFreeSubscriptions.push(doc.id));
            
            // check if they are listed on any institutional accounts as a member
            q_memberSnapshot.forEach((doc) => {
                // check that it is an institutional account
                if(doc.data().product == INSTITUTIONAL){
                    memberships.push(doc.id)
                }
            });

            // setup a listener for their subscription data
            if(subscriptions.length > 0){
                const subscriptionDoc = subscriptions[0]
                const listener = onSnapshot(doc(db, "subscriptions", subscriptionDoc), doc => {
                    const data = doc.data()
                    console.log("subscription listener " + doc.id)
                    // console.table(data)
                    setSubscription(data)
                    setUsers(data.members || [])
                })
                listeners.push(listener)
            } else {
                setSubscription({})
                setUsers([])
            }

            // setup a listener for their card-free subscription data
            if(cardFreeSubscriptions.length > 0){
                const cardFreeSubscriptionDoc = cardFreeSubscriptions[0]
                const listener = onSnapshot(doc(db, "subscriptions", cardFreeSubscriptionDoc), doc => {
                    if(doc.exists()){
                        const data = doc.data()
                        console.log("card-free subscription listener " + doc.id)
                        // console.table(data)
                        setCardFreeSubscription(data)
                        setUsers([])
                    }
                })
                listeners.push(listener)
            } else {
                setCardFreeSubscription({})
                setUsers([])
            }
            
            // setup listeners for the status of each of the accounts they are listed as a member for, and check they are still on the list
            if(memberships.length > 0){
                for(let membershipDoc of memberships){
                    const listener = onSnapshot(doc(db, "subscriptions", membershipDoc), doc => {
                        const subscription = doc.data()
                        // console.log("membership listener " + doc.id)
                        // console.table(subscription)
                        setMembership(doc.id, doc.data())
                    })
                    listeners.push(listener)
                }
            } else {
                resetMemberships()
            }
        }
        const listener = onAuthStateChanged(
            auth,
            user => {
                if(user){
                    console.log("auth listener got user: ", user.uid, user.email)
                    // zustand store
                    setUserId(user.uid)
                    setUserEmail(user.email)

                    // hook state
                    setUid(user.uid)
                    setEmail(user.email)

                    // load data
                    getUserMaps(user.uid)

                    // check account status
                    getUserAccount({
                        uid: user.uid, 
                        email: user.email
                    })
                } else {
                    console.log("auth listener got no user")
                    // zustand store
                    setUserId(null)
                    setUserEmail(null)

                    // hook state
                    setUid("")
                    setEmail("")
                    getUserAccount({uid: "none", email: "none"})
                    setMaps([])
                }
            },
            error => {
                console.log("auth listener got error:", error)
            },
        )
        listeners.push(listener)
        return () => {
            for(let unsubscribe of listeners){
                unsubscribe()
            }
        }
    }, [])
    return({
        uid,
        email,
        setEmail,
        maps,
        setMaps,
        users,
        setUsers
    })
}

const addUserToDb = async user => {
    console.log("adding user to DB", user.uid, user.email)
    const ref = doc(db, "users", user.uid)
    setDoc(ref, {
        uid: user.uid,
        email: user.email,
        maps: []
    })
}

export const saveMapAs = async ({ map, auth }) => {

    const { nodes, edges, setName, setMapId, notes, setLastUpdated } = map

    if(!auth.uid){
        alert("you must login to save")
        return
    }
    console.log("create new map in db")
    const id = uuid()
    setMapId(id)
    setLastUpdated(Date.now())

    const name = await prompt("name")
    if(!name){
        console.log("save as cancelled")
        return
    }
    setName(name)

    const mapRef = doc(db, "maps", id)
    const cleanNodes = nodes.map(node=>({
        ...node,
        data : {
            ...node.data,
            ref: null,
            textInputRef: null
        }
    }))
    await setDoc(mapRef, { 
        uid: id, 
        created: Date.now(),
        lastUpdated: Date.now(),
        name, 
        nodes: cleanNodes, 
        edges, 
        notes: notes.current 
    })

    const newMap = {
        uid: id,
        name
    }
    const userRef = doc(db, "users", auth.uid)
    await setDoc(userRef, {
        maps: [
            ...auth.maps,
            newMap
        ]
    })
    auth.setMaps([...auth.maps, newMap])

}

export const saveMap = async ({ map, auth, hasAccess }) => {
    const { nodes, edges, name, setName, setMapId, mapId, notes, setLastUpdated, setSaved } = map
    // console.log("saving map ", nodes, notes.current, edges, name, mapId)

    if(!auth.uid){
        alert("you must login to save")
        return
    }
    
    if(!mapId){ 
        // this is a new map
        console.log("create new map in db")

        const name = await prompt("name")
        if(!name){
            console.log("cancelled save")
            return
        }

        const id = uuid()
        setMapId(id)
        setName(name)
        setLastUpdated(Date.now())
        setSaved(true)

        const mapRef = doc(db, "maps", id)
        const cleanNodes = nodes.map(node=>({
            ...node,
            data : {
                ...node.data,
                ref: null,
                textInputRef: null
            }
        }))
        await setDoc(mapRef, {
            uid: id, 
            name, 
            nodes: cleanNodes, 
            edges, 
            notes: notes.current,
            created: Date.now(),
            lastUpdated: Date.now(),    
        })

        const newMap = {
            uid: id,
            name
        }
        const userRef = doc(db, "users", auth.uid)
        await setDoc(userRef, {
            maps: [
                ...auth.maps,
                newMap
            ]
        })
        auth.setMaps([...auth.maps, newMap])
    } else { 
        // this is not a new map
        setLastUpdated(Date.now())
        console.log("update map in db " + mapId)
        const ref = doc(db, "maps", mapId)
        const cleanNodes = nodes.map(node=>({
            ...node,
            data : {
                ...node.data,
                ref: null,
                textInputRef: null
            }
        }))
        setSaved(true)

        await setDoc(ref, { 
            uid: mapId, 
            name,
            nodes: cleanNodes, 
            edges, 
            notes: notes.current,
            created: Date.now(),
            lastUpdated: Date.now(),    
        })
    }
}

export const loadMap = async ({map, uid, name}) => {
    const {setEdges, setNodes, setName, setMapId, notes, setShouldCache, setLastUpdated, setSaved, setShouldLayout} = map
    console.log("load map")

    setNodes([])
    const docRef = doc(db, "maps", uid)
    const docSnap = await getDoc(docRef)
    if(docSnap.exists()){
        const data = docSnap.data()
        console.log("map:")
        console.log(data)
        setNodes(data.nodes)
        setEdges(data.edges)
        setMapId(uid)
        setName(name)
        setLastUpdated(data.lastUpdated)
        notes.current = data.notes
        setShouldCache(true)
        setSaved(true)
    } else {
        console.log("doc doesnt exist")
    }
}

export const deleteMap = async ({uid, auth}) => {
    console.log("delete map " + uid)

    // delete the map from DB
    const docRef = doc(db, "maps", uid)
    await deleteDoc(docRef)

    // delete the name from user in DB
    const userRef = doc(db, "users", auth.uid)
    const newMaps = auth.maps.filter(map=>map.uid != uid)
    setDoc(userRef, {
        maps: newMaps
    })
}

export const useMap = () => {
    const [target, setTarget] = useState(null)
    const [nodes, setNodes] = useState(InitialNodes);
    const [edges, setEdges] = useState(InitialEdges);
    const [name, setName] = useState("untitled")
    const [mapId, setMapId] = useState()
    const [showHelp, setShowHelp] = useState(false)
    const [shouldLayout, setShouldLayout] = useState(true);
    const [shouldCache, setShouldCache] = useState(false)
    const [moveSource, setMoveSource] = useState(null) // for keypress dnd
    const [notesOpen, setNotesOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showMiniMap, setShowMiniMap] = useState(false)
    const [lastUpdated, setLastUpdated] = useState(null)
    const [saved, setSaved] = useState(true)

    const [search, setSearch] = useSearchParams()

    const notes = useRef("")

    const states = useRef([{nodes, edges}])
    const index = useRef(0)

    const checkedURL = useRef(false)

    useEffect(()=>{
        if(checkedURL.current){
            return
        }
        checkedURL.current = true
        console.log("checking URL for search params")
        const uid = search.get("share") // the firebase key is the "share" query param
        const session_id = search.get("session_id")
        if( uid ){
            console.log("loading map " + uid);
            const run = async()=>{
                const docRef = doc(db, "maps", uid)
                const docSnap = await getDoc(docRef)
                if(!docSnap.exists()){
                    alert("the map you are trying to open was not found in our database")
                    return
                }
                const {nodes: newNodes, edges: newEdges, notes: newNotes, name: newName} = docSnap.data()
    
                if(!await confirm("Do you want to load \"" + newName + "\"")){
                    return
                }
                setNodes(newNodes)
                setEdges(newEdges)
                setName(newName)
                setMapId(undefined)
                notes.current = newNotes
                setSearch("")
            }
            run()
        }
        if( session_id ){
            console.log("STRIPE SUCCESS")
            handleSubscriptionSuccess(session_id)
        }
    }, [])

    useEffect(()=>{
        if(!shouldCache){
            return
        }
        const copy = states.current.slice(0, index.current + 1) // This removes all future (redo) states after current index
        copy.push({nodes, edges})
        // console.log(copy)
        states.current = copy
        index.current = copy.length - 1
        // console.log("cache " + index.current + " nodes:" + nodes.length + " edges:" + edges.length)
        setShouldCache(false)
        setSaved(false)
        // console.log("setsaved false")
        // console.log(nodes)
    },[shouldCache, nodes])    

    const undo = useCallback(() => {
        index.current -= (index.current > 0) // decriment if positive
        const {nodes: newNodes, edges: newEdges} = states.current[index.current]
        console.log("undo " + index.current + " nodes:" + newNodes.length + " edges:" + newEdges.length)
        setNodes(newNodes)
        setEdges(newEdges)
        setShouldLayout(true)
    }, [nodes, edges, index.current, states.current]);
    
    const redo = useCallback(() => {
        console.log("redo")
        index.current += (index.current < (states.current.length - 1)) // incriment but not out of bounds
        const {nodes: newNodes, edges: newEdges} = states.current[index.current]
        setNodes(newNodes)
        setEdges(newEdges)
        setShouldLayout(true)
    }, [nodes, edges, index.current, states.current])

    const share = useCallback(async url=>{
        if(!mapId){
            alert("you must save before you can share")
            return
        }
        // const url = "https://argumentation.io?share=" + mapId
        await navigator.clipboard.writeText(url)
        alert("Copied to clipboard")
        // alert("link to this argument map is " + url + "\n\nLink copied to clipboard")
    }, [mapId])

    return({ nodes, setNodes, edges, setEdges, name, setName, mapId, setMapId, showHelp, setShowHelp, shouldLayout, setShouldLayout, target, setTarget, undo, redo, setShouldCache, notes, moveSource, setMoveSource, share, notesOpen, setNotesOpen, loading, setLoading, showMiniMap, setShowMiniMap, lastUpdated, setLastUpdated, saved, setSaved })
  }

  console.log("firebase initialized")

import create from 'zustand';
import {INSTITUTIONAL, INDIVIDUAL_MONTHLY, INDIVIDUAL_BIANNUAL, INDIVIDUAL_CARD_FREE} from './subscription'

export const useStore = create(set => ({
    // which modal is open if any
    openModal: null,
    setOpenModal: (name) => set(state => ({ openModal: name })),

    // the userId if logged in
    userId: null,
    setUserId: (uid) => set(state => ({userId: uid})),

    // the userId if logged in
    userEmail: null,
    setUserEmail: (email) => set(state => ({userEmail: email})),

    // the subscription details if subscribed
    subscription: {},
    setSubscription: (data) => set(state =>({subscription: data})),
    
    // the card-free subscription details if subscribed
    cardFreeSubscription: {},
    setCardFreeSubscription: (data) => set(state =>({cardFreeSubscription: data})),

    // the subscriptions that the user is a member on
    memberships: {},
    setMembership: (key, val) => set(state =>({
        memberships: {
            ...state.memberships,
            [key]: val
        }
    })),
    resetMemberships: () => set(state => ({memberships: {}})),

    // if the user is granted membership by at least one other subscription
    isMember: false,
    setIsMember: (is) => set(state =>({isMember: is})),

    alert: {
        show: false,
        title: "TEST ALERT"
    },
    setAlert: (newAlert) => set(state => ({alert: newAlert})),

    confirm: {
        show: false,
        title: "TEST CONFIRM"
    },
    setConfirm: (newConfirm) => set(state => ({confirm: newConfirm})),
    
    prompt: {
        show: false,
        title: "Rename Map",
        text: "",
    },
    setPrompt: (newPrompt) => set(state => ({prompt: newPrompt})),
    setPromptText: (newText) => set(state => ({prompt: {...state.prompt, text: newText}})),

    // aria-live data
    status: "no status",
    setStatus: s => set(state => ({status: s}))
}));

// selectors
export const useSubscription = () => {

    let data = {}

    const userId = useStore(s=>s.userId)

    let subscription = "none"
    const subscriptionData = useStore(state => state.subscription)

    if(subscriptionData.active == true){
        subscription = "active"
    } else if(subscriptionData.active == false){
        subscription = "expired"

/* 
******************************
this is temporary the bug fix
******************************
*/
    } else if(subscriptionData.paid == "pending"){
        subscription = "active"
    }
/* 
******************************
this is temporary the bug fix
******************************
*/

    // console.log({subscription})

    let cardFreeSubscription = "none"
    const cardFreeSubscriptionData = useStore(state => state.cardFreeSubscription)
    if(cardFreeSubscriptionData.active == true){
        cardFreeSubscription = "active"
    } else if (cardFreeSubscriptionData.active == false){
        cardFreeSubscription = "expired"
    }
    
    let isMember = false
    const ms = useStore(state => state.memberships)
    const email = useStore(state => state.userEmail)
    const memberships = Object.values(ms)
    if(memberships.some(m=>m.active && m.members.includes(email))){
        isMember = true
    }

    if(subscriptionData.active == true){
        data = {...subscriptionData}
    } else if(cardFreeSubscriptionData.active == true){
        data = {...cardFreeSubscriptionData}
    }

    const showManageAccount = subscription != "none"
    const showSubscribe = subscription == "none"
    const hasAccess = (subscription == "active") || (cardFreeSubscription == "active") || isMember
    const isIndividual = (subscriptionData.product == INDIVIDUAL_MONTHLY) || (subscriptionData.product == INDIVIDUAL_BIANNUAL)
    const isCardFreeIndividual = cardFreeSubscriptionData.product == INDIVIDUAL_CARD_FREE
    const isInstitutional = subscriptionData.product == INSTITUTIONAL
    const isLoggedIn = userId != null
    const showRedeem = hasAccess == false

    return { subscription, isMember, showManageAccount, showSubscribe, showRedeem, hasAccess, data, isIndividual, isCardFreeIndividual, isInstitutional, isLoggedIn }
}
import { useStore } from "../../utils/store";

export const Plans = () => {
    const userId = useStore(s=>s.userId)
    console.log("uid: " + userId)
    if(!userId){
        return(
            <div style={{display:"flex", alignItems: "center", flexDirection: "column", width: "100%", height:"100%"}}>
                <div style={{display:"flex", alignItems: "center", flexDirection: "column", margin:"auto", border: "3px solid gold", padding: 10}}>
                    <p style={{textAlign:"center"}}>
                        SORRY, YOU HAVE ACCESSED THIS PAGE IN A BAD WAY.
                    </p>
                    <p style={{textAlign:"center"}}>
                        YOU MUST HAVE AN ACCOUNT ON ARGUMENTATION.IO, BE LOGGED IN, AND THEN CLICK -SUBSCRIBE- TO CREATE A SUBSCRIPTION.
                    </p>
                    <p style={{textAlign:"center"}}>
                        PLEASE RETURN TO <a href="https://www.argumentation.io">ARGUMENTATION.IO</a> TO CREATE AN ACCOUNT.
                    </p>
                </div>
            </div>
        )
    }
    return(
        <div>
            <stripe-pricing-table 
                // pricing-table-id="prctbl_1MvOB4DfUPzyQxTKsRzTnnr3" // test
                // publishable-key="pk_test_51MtvjEDfUPzyQxTK0zm69DxI6C8xEKZjcDlJtE0iZj7af3TaG4jsu4n4j2tKMaEIfq11BnXer8x6vjUUMapHfDhr00tVCDjByV"
                pricing-table-id="prctbl_1N1sg1DfUPzyQxTK3ZWlXg2q" // live
                publishable-key="pk_live_51MtvjEDfUPzyQxTKUpEThCFEJMGQn0ot4q6Snq3kPuVkVIURBgAlhsZl9ERJgOx180bYNaSOQOaDghZVmQ1HcOCR00GLYb2KrP"
                client-reference-id={userId || "noUserIdFound"}
            >
            </stripe-pricing-table>
            <div>
                <p style={{margin:"auto", textAlign:'center'}}><a href="mailto:argumentationcompany@gmail.com?subject=Institutional License Inquiry">Contact Us</a> for Institutional subscriptions</p>
            </div>
        </div>
    )
}
import "../../index.css"
import 'tippy.js/dist/tippy.css';
import { Link } from "react-router-dom";
import { loginMicrosoft, loginEmail, createAccountEmail, logout, loadMap, saveMap, saveMapAs, deleteMap, updateUsersList, changeEmail, resetPassword, forgotPassword } from '../../utils/firebase'
import React, { useEffect, useState, useCallback, useRef } from "react";
import { InitialEdges, InitialNodes } from "../../templates/initialDiagram";
import { AiFillLock, AiOutlineAudit, AiOutlineUserAdd, AiOutlineMail, AiOutlineMenu, AiOutlineUser, AiFillFolderOpen, AiOutlineSisternode, AiOutlineDelete, AiOutlineSave, AiOutlineFilePdf, AiOutlineFileImage, AiOutlineFileAdd, AiOutlineQuestionCircle } from "react-icons/ai"
import { BiUserCircle, BiShare, BiBadgeCheck } from "react-icons/bi";
import { MdOutlinePrivacyTip, MdAccessibilityNew } from "react-icons/md";
import { BsBook, BsLink45Deg } from "react-icons/bs";
import { RiTicketLine } from "react-icons/ri";
import { downloadPdf, downloadPng, renderPngToUrl } from "../../utils/render";
import { useStore, useSubscription } from "../../utils/store";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import {Toolbar} from 'react-aria-components';
import { alert, confirm, prompt } from "../subcomponents/Alert";
import { redeemCode } from "../../utils/subscription";
import { MS, MS_DARK } from "../../assets/svgs";


const smallIcon = 22

const Menu = ({map, auth, mapInstance, setIsRendering}) => {

    const { setNodes, setEdges, mapId, setMapId, name, setName, setShouldLayout, notes, share, setLoading, saved, setSaved } = map
    const { users, setUsers } = auth
    const [open, _setOpen] = useState(false)
    const [showLoadModal, setShowLoadModal] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showAccountModal, setShowAccountModal] = useState(false)
    const [showShareModal, setShowShareModal] = useState(false)
    const [showSnapshotModal, setShowSnapshotModal] = useState(false)
    const [snapShotLink, setSnapShotLink] = useState(false)

    const setOpenModal = useStore(s=>s.setOpenModal)
    const openModal = useStore(s=>s.openModal)

    const { subscription, membership, showManageAccount, showSubscribe, hasAccess, isLoggedIn, showRedeem } = useSubscription()
    // console.log("hasAccess :" + hasAccess)
    // console.table({ subscription, membership, showManageAccount, showSubscribe, hasAccess })

    const setOpen = useCallback((should_open)=>{
        if(should_open){
            _setOpen(true)
            setOpenModal("menu")
        } else {
            _setOpen(false)
            setOpenModal(null)
        }
    }, [])
    
    useEffect(()=>{
        // close the menu when click off the menu
        const menu = document.getElementById("menu")
        const handleClick = e=>{
            if((e.target != menu) && (!isDescendant(menu, e.target))){
                setOpen(false)
                setOpenModal(null)
            }
        }
        if(open){
            document.addEventListener("click", handleClick)
        } else {
            document.removeEventListener("click", handleClick)
        }
        return () => document.removeEventListener("click", handleClick)
    },[open])

    const newMap = useCallback(async () => {
        if(!saved){
            const cont = await confirm("You have unsaved changes, are you sure you want to start a new map?")
            if(!cont) return;
        }
        setNodes(InitialNodes())
        setEdges(InitialEdges)
        setMapId(undefined)
        setName("")
        setOpen(false)
        notes.current = "notes"
        setShouldLayout(true)
        setTimeout(()=>{
            // mapInstance.current.fitView()
            mapInstance.current.setViewport({
                x:0,
                y:0,
                zoom:1
            })
            setSaved(true)
        },100)
    }, [setNodes, setEdges, setMapId, setName, saved, setSaved])
    
    const {uid, maps} = auth

    return (
        <div id='menu'>
            <div className="menu-icon">
                <Button 
                    variant="outline-light"
                    onClick={()=>_setOpen(o => !o)}
                    style={{ marginLeft: 2, marginRight: 2 }}
                    size="sm"
                    aria-label="main menu"
                    role="button"
                    aria-expanded={open}
                >
                    <AiOutlineMenu size={14} color="black" aria-hidden/>
                </Button>
            </div>
            <Modal bsPrefix="menu-modal" show={open} onHide={()=>setOpen(false)} backdrop={true} autoFocus>
                <Toolbar orientation="vertical" aria-label="main menu">
                    <ul style={{marginLeft:0, padding:0}}>

                        {/* NEW MAP */}
                        <MenuButton 
                            text="New Map"
                            onClick={newMap}
                        >
                            <AiOutlineFileAdd size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>

                        {/* SAVE */}
                        <MenuButton 
                            text="Save"
                            onClick={async ()=>{
                                await saveMap({ map, auth })
                                setOpen(false)
                            }}
                            active = {hasAccess}
                        >
                            <AiOutlineSave size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>

                        {/* SAVE AS */}
                        <MenuButton 
                            text="Save as"
                            onClick={async ()=>{
                                await saveMapAs({ map, auth })
                                setOpen(false)
                            }}
                            active = {hasAccess}
                        >
                            <AiOutlineSave size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>

                        {/* LOAD */}
                        <MenuButton 
                            text="Load"
                            onClick={()=>{
                                if(!uid){
                                    alert("you must be logged in to load a map.")
                                    return
                                }
                                if(!auth.maps.length){
                                    alert("you dont have any saved maps.")
                                    return
                                }
                                if(showLoadModal){
                                    setShowLoadModal(false)
                                    setOpenModal("menu")
                                } else {
                                    setShowLoadModal(true)
                                    setOpenModal("load")
                                }
                            }}
                            active = {hasAccess}
                        >
                            <AiFillFolderOpen size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>

                        {/* LOGIN/OUT */}
                        <MenuButton 
                            text={uid ? "Logout" : "Login / Create Account"}
                            onClick={()=>{
                                if(uid){
                                    logout({map, auth})
                                } else {
                                    setShowLoginModal(true)
                                    setOpenModal("login")
                                } 
                            }}
                        >
                            <AiOutlineUser size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>

                        <Seperator/>

                        {/* SAVE JPG */}
                        <MenuButton 
                            text="Export as .png"
                            onClick={async () => {
                                if(!map || !map.mapId){
                                    alert("you must save a map before exporting.")
                                    return
                                }
                                if(!map.saved){
                                    const cont = await confirm("You have unsaved changes that will not be reflected in the saved .jpg, do you want to continue?")
                                    if(!cont) return;
                                }
                                setLoading(true)
                                setIsRendering(true)
                                await new Promise(res => setTimeout(() => res(), 100))
                                mapInstance.current.fitView()
                                await new Promise(res => setTimeout(() => res(), 100))
                                await downloadPng({
                                    mapId: map.mapId, 
                                    name: map.name
                                })
                                setIsRendering(false)
                                setOpen(false)
                                // await new Promise(res => setTimeout(() => res(), 1000))
                                setLoading(false)
                            }}
                            active = {hasAccess}
                        >
                            <AiOutlineFileImage size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>

                        {/* SAVE PDF */}
                        <MenuButton 
                            text="Export as .pdf"
                            onClick={async ()=>{
                                if(!map.mapId){
                                    alert("you must save a map before exporting.")
                                    return
                                }
                                if(!map.saved){
                                    const cont = await confirm("You have unsaved changes that will not be reflected in the saved .pdf, do you want to continue?")
                                    if(!cont) return;
                                }
                                setLoading(true)
                                setIsRendering(true)
                                await new Promise(res => setTimeout(() => res(), 100))
                                mapInstance.current.fitView()
                                await new Promise(res => setTimeout(() => res(), 100))
                                await downloadPdf({mapId, name})
                                setIsRendering(false)
                                setOpen(false)
                                // await new Promise(res => setTimeout(() => res(), 1000))
                                setLoading(false)
                            }}
                            active = {hasAccess}
                        >
                            <AiOutlineFilePdf size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>

                        {/* PNG URL */}
                        <MenuButton 
                            text="Share snapshot"
                            onClick={async () => {
                                if(!map || !map.mapId){
                                    alert("you must save a map before exporting.")
                                    return
                                }
                                if(!map.saved){
                                    const cont = await confirm("You have unsaved changes that will not be reflected in the saved link, do you want to continue?")
                                    if(!cont) return;
                                }
                                setLoading(true)
                                setIsRendering(true)
                                await new Promise(res => setTimeout(() => res(), 100))
                                mapInstance.current.fitView()
                                await new Promise(res => setTimeout(() => res(), 100))
                                const url = await renderPngToUrl({
                                    mapId: map.mapId, 
                                    name: map.name
                                })
                                if(url){
                                    setSnapShotLink(url)
                                    setShowSnapshotModal(true)
                                }
                                setIsRendering(false)
                                setOpen(false)
                                // await new Promise(res => setTimeout(() => res(), 1000))
                                setLoading(false)
                            }}
                            active = {hasAccess}
                        >
                            <BsLink45Deg size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>

                        {/* SHARE */}
                        <MenuButton 
                            text="Share"
                            onClick={()=>{
                                if(!map.mapId){
                                    alert("you must save a map before sharing.")
                                    return
                                }
                                setShowShareModal(true)
                                setOpenModal("share")
                            }}
                            active = {hasAccess}
                        >
                            <BiShare size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>


                        <Seperator/>

                        {/* MANAGE ACCOUNT */}
                        <MenuButton 
                            text="Manage Account"
                            onClick={() => {
                                if(showLoadModal){
                                    setShowAccountModal(false)
                                    setOpenModal("menu")
                                } else {
                                    setShowAccountModal(true)
                                    setOpenModal("account")
                                }
                            }}
                            active = {isLoggedIn}
                        >
                            <BiUserCircle size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>

                        {/* SUBSCRIBE */}
                        {
                            showSubscribe && isLoggedIn &&
                            <Link 
                                to="/plans.html"
                                tabIndex={0}
                                className="btn btn-outline-light btn-sm"
                                style={{width:"100%"}}
                            >
                                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                    <BiBadgeCheck size={smallIcon} color="grey" aria-hidden/>
                                    <div style={{color:"black", marginLeft: "10px"}}>
                                        Subscribe
                                    </div>
                                </div>
                            </Link>
                        }

                        {/* REDEEM */}
                        {
                            showRedeem && isLoggedIn &&
                            <MenuButton 
                                text="Reedem Code"
                                onClick={async ()=>{
                                    const code = await prompt("Please enter your code.")
                                    if(!code)return
                                    setLoading(true)
                                    const {res, error} = await redeemCode(code)
                                    setLoading(false)
                                    if(error){
                                        console.log(error)
                                        alert("Server error, please try again")
                                    } else if(res == "success") {
                                        await alert("Token redeemed, your membership will expire in 6 months.")
                                        location.reload()
                                    } else if(res == "not found") {
                                        alert("Code invalid")
                                    } else if(res == "used") {
                                        alert("Code has already been redeemed")
                                    } else {
                                        alert("Code invalid or expired")
                                    }
                                }}
                            >
                                <RiTicketLine size={smallIcon} color="grey" aria-hidden/>
                            </MenuButton>
                        }

                        {/* CONTACT US */}
                        <MenuButton 
                            text="Contact"
                            onClick={() => {
                                const email = "argumentationcompany@gmail.com"
                                const subject = "Support for Argumentation.io"
                                const emailBody = "Dear Argumentation.io,\n"
                                document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody
                            }}
                        >
                            <AiOutlineMail size={smallIcon} color="grey" aria-hidden/>
                        </MenuButton>

                        {/* HOWTO */}
                        <Link
                            to="https://argumentation-llc.github.io/howto/"
                            target="_blank"
                            tabIndex={0}
                            className="btn btn-outline-light btn-sm"
                            style={{width:"100%"}}
                        >
                            <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                <AiOutlineQuestionCircle size={smallIcon} color="grey" aria-hidden/>
                                <div style={{color:"black", marginLeft: "10px"}}>
                                    How To
                                </div>
                            </div>
                        </Link>

                        {/* TEXTBOOK */}
                        {
                            (!showSubscribe || hasAccess) &&
                            <Link
                                target="_blank"
                                to="/With_Good_Reason.pdf"
                                download
                            >
                                <MenuButton text="Textbook">
                                    <BsBook size={smallIcon} color="grey" aria-hidden/>
                                </MenuButton>
                            </Link>
                        }

                        <Seperator/>

                        {/* PRIVACY */}
                        <Link
                            target="_blank"
                            to="/privacy.html"
                        >
                            <MenuButton text="Privacy Policy">
                                <MdOutlinePrivacyTip size={smallIcon} color="grey" aria-hidden/>
                            </MenuButton>
                        </Link>

                        {/* TERMS */}
                        <Link
                            target="_blank"
                            to="/terms-and-conditions.html"
                        >
                            <MenuButton text="Terms and Conditions">
                                <AiOutlineAudit size={smallIcon} color="grey" aria-hidden/>
                            </MenuButton>
                        </Link>

                        {/* ACCESSIBILITY STATEMENT */}
                        <Link
                            target="_blank"
                            to="/accessibility-statement.html"
                        >
                            <MenuButton text="Accessibility Statement">
                                <MdAccessibilityNew size={smallIcon} color="grey" aria-hidden/>
                            </MenuButton>
                        </Link>

                    </ul>
                </Toolbar>
            </Modal>
            <LoginModal open={showLoginModal} auth={auth} close={()=>setShowLoginModal(false)}/>
            <LoadModal  open={showLoadModal} map={map} auth={auth} close={()=>setShowLoadModal(false)}/>
            <AccountModal open={showAccountModal} auth={auth} users={users} setUsers={setUsers} close={()=>setShowAccountModal(false)}/>
            <ShareModal 
                open={showShareModal} 
                link={"https://argumentation.io?share=" + mapId}
                share={share} 
                close={()=>setShowShareModal(false)}
            />
            <SnapshotModal
                open={showSnapshotModal} 
                link={snapShotLink}
                share={share} 
                close={()=>setShowSnapshotModal(false)}
            />
        </div>
    )
}

const LoadModal = ({map, auth, close, open}) => 
    <Modal 
        show={open} 
        onHide={close} 
        size="xl" 
        scrollable={true}
        aria-labelledby="load-modal_title"
    >
            <Modal.Header closeButton>
                <div className="modal-title" id="load-modal_title">
                    LOAD ARGUMENT MAP
                </div>
            </Modal.Header>
            <Modal.Body>
                {auth.maps.map(({uid, name, created, lastUpdated}, i)=>
                    <div 
                        style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"center"}}
                        key={uid}
                    >
                        <Button 
                            key={uid}
                            style={{borderColor:"gold", color:"black", width:"80%", margin:2, marginRight:10, alignItems:"center", display:"flex"}}
                            onClick={async ()=>{
                                if(!map.saved){
                                    const cont = await confirm("You have unsaved changes, are you sure you want to load?")
                                    if(!cont) return;
                                }
                                await loadMap({map, uid, name})
                                close()
                                // setOpen(false)
                                setTimeout(()=>map.setSaved(true), 500)
                            }}
                            variant="outline-light"
                            tabIndex={0}
                            aria-label={"Load argument map " + name}
                        >
                            {name}
                        </Button>
                        <Button
                            variant="outline-light"
                            onClick={async e=>{
                                e.stopPropagation() // without stopPropogation the map button also gets the click and it tries to load the now deleted map
                                if(await confirm("Delete " + name + "?")){
                                    deleteMap({uid, auth})
                                }
                            }}
                            style={{ borderColor:"transparent"}}
                            size="sm"
                            aria-label={"delete argument map " + name}
                            tabIndex={0}                             
                        >
                            <AiOutlineDelete size={smallIcon} color="grey" aria-hidden/>
                        </Button>
                    </div>
                )}
            </Modal.Body>
    </Modal>

const AccountModal = ({users, setUsers, close, auth, open}) => {
    const { subscription, isMember, showManageAccount, showSubscribe, hasAccess, data, isInstitutional, isIndividual, isCardFreeIndividual, isLoggedIn } = useSubscription()
    const userId = useStore(s=>s.userId)
    const email = useStore(s=>s.userEmail)
    const setEmail = useStore(s=>s.setUserEmail)

    return(
        <Modal show={open} onHide={close} size="xl" scrollable={true}>
            <Modal.Header closeButton>
                <div className="modal-title">
                    MANAGE ACCOUNT
                </div>
            </Modal.Header>
            <Modal.Body>              
                { 
                    showManageAccount &&
                    <Link
                        // to="https://billing.stripe.com/p/login/test_00gg230PQ94P3jWcMM"
                        to="https://billing.stripe.com/p/login/aEU3gc0jH1eZ676144"
                        className="btn btn-success btn-blue         "
                        style={{width:"100%",backgroundColor:"#3EA1C6", borderColor:"transparent", margin:2, textAlign: "left", color: "black"}}
                    >
                            <BiBadgeCheck size={smallIcon} color="black" style={{marginLeft:"30%", marginRight:10}} aria-hidden/>
                            Manage Subscription
                    </Link>
                }
                <Button
                    style={{width:"100%",backgroundColor:"#3EA1C6", borderColor:"transparent", margin:2, textAlign: "left", color: "black"}}
                    variant="success"
                    aria-label={"Change Email Address"}
                    tabIndex={0}  
                    onClick={()=>changeEmail({auth, setEmail})}  
                    className="btn-blue"          
                >
                    <AiOutlineMail size={smallIcon} color="black" style={{marginLeft:"30%", marginRight:10}} aria-hidden/>
                    Update Email
                </Button>
                <Button
                    style={{width:"100%",backgroundColor:"#3EA1C6", borderColor:"transparent", margin:2, textAlign: "left", color: "black"}}
                    variant="success"
                    aria-label={"Reset Password"}
                    tabIndex={0}
                    onClick={()=>resetPassword({ auth, email })}   
                    className="btn-blue"         
                >
                    <AiFillLock size={smallIcon} color="black" style={{marginLeft:"30%", marginRight:10}} aria-hidden/>
                    Reset Password
                </Button>

                <div style={{width:"100%", borderTop:"1px solid grey", marginInline:"auto", marginBlock:10}}/>
                <div style={{width:"100%", marginInline:"auto", flexDirection:"row", display:"flex"}}>
                    <div style={{flexDirection:"column"}}>
                        <div><b>Subscription:</b></div>
                        <div><b>Status:</b></div>
                        <div><b>Created:</b></div>
                        { isCardFreeIndividual ?
                            <div><b>Started:</b></div> :
                            <div><b>Last invoice:</b></div>
                        }
                        { isCardFreeIndividual ?
                            <div><b>Expires:</b></div> :
                            <div><b>Next invoice:</b></div>
                        }
                    </div>
                    <div style={{marginLeft:"10px",flexDirection:"column"}}>
                        <div>{isInstitutional ? "Institutional" : (isIndividual || isCardFreeIndividual) ? "Individual" : "None"}</div>
                        <div>{data.active ? "Active" : "Inactive"}</div>
                        <div>{data.created ? new Date(data.created * 1000).toLocaleDateString() : "NA"}</div>
                        <div>{data.start ? new Date(data.start * 1000).toLocaleDateString() : "NA"}</div>
                        <div>{data.end ? new Date(data.end * 1000).toLocaleDateString() : "NA"}</div>
                    </div>
                </div>
                <div style={{width:"100%", borderTop:"1px solid grey", marginInline:"auto", marginBlock:10}}/>
                {
                    isInstitutional && 
                    <div style={{marginLeft:"10%", fontWeight:"bold", color:"grey", marginBottom:2, marginTop:20}}>
                        REGISTERED USERS
                    </div>
                }
                {
                    isInstitutional && 
                    <div 
                        // style={{overflow:"scroll"}}
                    >
                        <div>
                        {
                            (!users || (users.length == 0)) &&
                            <div style={{color:"grey", width:"80%", marginInline:"auto"}}>
                                no users yet
                            </div>
                        }
                        {
                            users && users.map((name, i)=>
                                <div 
                                    style={{width:"100%", display:"flex", flexDIrection:"column", justifyContent:"center"}}
                                    key={name}
                                >
                                    <div style={{width:"80%", display:"flex", flexDirection:"row", border:"1px solid grey", borderTopWidth: i==0 ? 1 : 0}}>
                                        <div 
                                            style={{ color:"black", width:"80%", margin:2, marginLeft:20, color:"grey" }}
                                            tabIndex={0}
                                            aria-label={"user email " + name}
                                        >
                                            {name}
                                        </div>
                                        <Button
                                            variant="outline-light"
                                            onClick={async e=>{
                                                e.stopPropagation() // without stopPropogation the map button also gets the click and it tries to load the now deleted map
                                                if(await confirm("Remove " + name + " from your account ?")){
                                                    setUsers(users => users.filter(x => x!=name))
                                                    updateUsersList(users.filter(x => x!=name))

                                                }
                                            }}
                                            style={{ marginLeft:"auto", marginRight:8}}
                                            size="sm"
                                            aria-label={"delete user " + name}
                                            tabIndex={0}              
                                        >
                                            <AiOutlineDelete size={smallIcon} color="grey" aria-hidden/>
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                        </div>
                        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", marginTop:10}}>
                            <Button 
                                style={{width:"80%", backgroundColor:"gold", borderColor:"white", marginBottom:30}}
                                onClick={async ()=>{
                                    const email = await prompt("Please enter email address of user to be added.")
                                    if(!email) return
                                    if(users && users.includes(email)){
                                        alert(email + " is already in the list.")
                                        return
                                    }
                                    setUsers(users => [...users, email])
                                    updateUsersList([...users, email])
                                }}
                                variant="success"
                                aria-label="add user to account"
                                tabIndex={0}
                            >
                                <AiOutlineUserAdd size={smallIcon} color="white" style={{marginRight:20}} aria-hidden/>
                                ADD USER
                            </Button>
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

const INVALID_EMAIL = "auth/invalid-email"
const PASSWORD_INCORRECT = "auth/wrong-password"
const USER_NOT_FOUND = "auth/user-not-found"
const EMAIL_IN_USE = "auth/email-already-in-use"
const WEAK_PASSWORD = "auth/weak-password"

const LoginModal = ({open, close}) => {
    const emailRef = useRef()
    const passwordRef = useRef()
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [hidePassword, setHidePassword] = useState(true)
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [createAccountEmailInvalid, setCreateAccountEmailInvalid] = useState(false)
    const [passwordInvalid, setPasswordInvalid] = useState(false)
    const [createAccountPasswordInvalid, setCreateAccountPasswordInvalid] = useState(false)
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [showCreateAccount, setShowCreateAccount] = useState(false)

    const login = useCallback(
        async () => {
            const email = emailRef.current.value || ""
            const password = passwordRef.current.value || ""

            if(!email.trim().length)
            {
                setEmailInvalid(true)
                setEmailError("email cannot be blank.")
                return
            }
            
            if(!password.trim().length){
                setPasswordInvalid(true)
                setPasswordError("password cannot be blank.")
                return
            }
            
            const { user, error } = await loginEmail({ email, password })
            if(error){
                switch(error.code){
                    case INVALID_EMAIL:
                        setEmailInvalid(true)
                        setPasswordInvalid(false)
                        setEmailError("Invalid email address.")
                        break;
                    case PASSWORD_INCORRECT:
                        setEmailInvalid(false)
                        setPasswordInvalid(true)
                        setPasswordError("Password is incorrect.")
                        break;
                    case USER_NOT_FOUND:
                        setEmailInvalid(true)
                        setPasswordInvalid(false)
                        setEmailError("User not found.")
                        break;    
                    default:
                        console.log(error.code + " unhandled")
                }
                alert(error.code)
            } else {
                setEmailInvalid(false)
                setPasswordInvalid(false)
                setEmailError("")
                setPasswordError("")
                console.log("login success")
                close()
                setTimeout(()=>alert("Logged in as " + email + "."),100)
            }
        }, [ emailRef, passwordRef]
    )

    const createAccount = useCallback(
        async () => {

            const email = emailRef.current ? emailRef.current.value : ""
            const password = password1

            if(!email.trim().length)
            {
                setCreateAccountEmailInvalid(true)
                setEmailError("email cannot be blank.")
                return
            }
            
            if(!password.trim().length){
                setCreateAccountPasswordInvalid(true)
                setPasswordError("password cannot be blank.")
                return
            }

            const { user, error } = await createAccountEmail({ email, password })
            if(error){
                switch(error.code){
                    case EMAIL_IN_USE:
                        setCreateAccountEmailInvalid(true)
                        setCreateAccountPasswordInvalid(false)
                        setEmailError("email address is already in use.")
                        break
                    case WEAK_PASSWORD:
                        setCreateAccountEmailInvalid(false)
                        setCreateAccountPasswordInvalid(true)
                        setPasswordError("password is too weak.")
                        break
                    default:
                        console.log(error.code + " unhandled")
                        setCreateAccountEmailInvalid(true)
                        setCreateAccountPasswordInvalid(true)
                        setEmailError("unknown error.")
                        setPasswordError("unknown error.")
                        break
                }
                alert(error.code)
            } else {
                console.log("login success", user)
                alert("account created successfully for " + email)
                setCreateAccountEmailInvalid(false)
                setCreateAccountPasswordInvalid(false)
                setEmailError("")
                setPasswordError("")
                close()
            }
        }, [ emailRef, passwordRef, password1, password2 ]
    )

    const handleChangePassword = useCallback((e, pw)=>{
        const text = e.target.value
        if(pw == 1){
            setPassword1(text)
            if(text != password2){
                setCreateAccountPasswordInvalid(true)
                setPasswordError("Passwords do not match")
            } else {
                setCreateAccountPasswordInvalid(false)
            }
        } else {
            setPassword2(text)
            if(text != password1){
                setCreateAccountPasswordInvalid(true)
                setPasswordError("Passwords do not match")
            } else {
                setCreateAccountPasswordInvalid(false)
            }
        }
    }, [password1, password2])

    return(
        showCreateAccount 
        ?

        // 
        // CREATE ACCOUNT
        // 

        <Modal 
            show={open} 
            onHide={()=>close()}
            size="lg"
            aria-labelledby="create-account-modal-header"
        >
            <Modal.Header closeButton role="heading" aria-level={1}>
                <div className="modal-title" id="create-account-modal-header">
                    CREATE ACCOUNT
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={e=>e.preventDefault()} noValidate >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                            controlId="floatingEmailInput"
                            label="Email Address"
                        >
                            <Form.Control
                                ref={emailRef}
                                type="email" 
                                placeholder="Enter email" 
                                required
                                autoFocus
                                aria-required="true"
                                isInvalid={createAccountEmailInvalid}
                                aria-invalid={createAccountEmailInvalid}
                                aria-describedby="email-error"
                            />
                            <Form.Control.Feedback type="invalid" id="email-error">
                                {createAccountEmailInvalid && emailError}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <FloatingLabel
                            controlId="floatingPasswordInput"
                            label="Password"
                        >
                            <Form.Control
                                value={password1}
                                onChange={e=>handleChangePassword(e, 1)}
                                type={hidePassword ? "password" : "text"}
                                required
                                placeholder="Password" 
                                aria-required="true"
                                isInvalid={createAccountPasswordInvalid}
                                aria-invalid={createAccountPasswordInvalid}
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingPassword2Input"
                            label="Confirm Password"
                        >
                            <Form.Control
                                style={{marginTop:5}}
                                value={password2}
                                onChange={e=>handleChangePassword(e, 2)}
                                type={hidePassword ? "password" : "text"}
                                required
                                placeholder="Confirm password" 
                                aria-required="true"
                                isInvalid={createAccountPasswordInvalid}
                                aria-invalid={createAccountPasswordInvalid}
                            />
                            <Form.Control.Feedback type="invalid" id="password-error">
                                {createAccountPasswordInvalid && passwordError}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                        <Form.Check
                            style={{margin: 3, color:"grey", fontSize:13}}
                            type="checkbox"
                            label="show password"
                            onChange={()=>setHidePassword(checked => !checked)}
                            checked={!hidePassword}
                        />
                        <Button
                            style={{marginTop:0}}
                            variant="warning" 
                            type="submit" 
                            onClick={()=>createAccount()}
                            disabled={createAccountPasswordInvalid || (password1.length == 0)}
                        >
                            CREATE NEW ACCOUNT
                        </Button>
                        <div>
                            <Button
                                variant="link"  
                                style={{marginTop:10, marginLeft:3, padding:0, fontSize:12}} 
                                onClick={()=>setShowCreateAccount(false)}
                            >
                                I already have an account
                            </Button>
                        </div>
                    </Form.Group>
                    <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
                        <div style={{flexDirection:"row", width:"100%", display:"flex", alignItems:"center", marginBottom:10}}>
                            <div style={{flex:1, borderTop:"1px solid grey"}}/>
                            <div style={{marginInline:10, color:"grey"}}>
                                or
                            </div>
                            <div style={{flex:1, borderTop:"1px solid black"}}/>
                        </div>
                        <button 
                            aria-label="Sign in with Microsoft"
                            style={{marginBlock:10, border: "none", padding:0, backgroundColor: "transparent"}}
                            onClick={async ()=>{
                                const success = await loginMicrosoft()
                                if(success){
                                    close()
                                    const email = useStore.getState().userEmail
                                    setTimeout(
                                        ()=>alert("Logged in as " + email + "."),
                                        100
                                    )                
                                } else {
                                    alert("Failed to create account.")
                                }
                            }}
                        >
                            <MS/>
                        </button>
                    </div>
                </Form> 
            </Modal.Body>
        </Modal>
        :

        // 
        // LOGIN
        // 

        <Modal 
            show={open} 
            onHide={()=>close()}
            size="lg"
            aria-labelledby="login-modal-header"
        >
            <Modal.Header closeButton role="heading" aria-level={1}>
                <div className="modal-title" id="login-modal-header">
                    {
                        showCreateAccount ? "CREATE ACCOUNT" : "LOGIN"
                    }
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={e=>e.preventDefault()} noValidate >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                            controlId="floatingEmailInput"
                            label="Email Address"
                        >
                            <Form.Control
                                ref={emailRef}
                                type="email" 
                                placeholder="Enter email" 
                                required
                                autoFocus
                                aria-required="true"
                                isInvalid={emailInvalid}
                                aria-invalid={emailInvalid}
                                aria-describedby="email-error"
                            />
                            <Form.Control.Feedback type="invalid" id="email-error">
                                {emailInvalid && emailError}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <FloatingLabel
                            controlId="floatingPasswordInput"
                            label="Password"
                        >
                            <Form.Control
                                ref={passwordRef}
                                type={hidePassword ? "password" : "text"}
                                required
                                placeholder="Password" 
                                aria-required="true"
                                isInvalid={passwordInvalid}
                                aria-invalid={passwordInvalid}
                            />
                            <Form.Control.Feedback type="invalid" id="password-error">
                                {passwordInvalid && passwordError}
                            </Form.Control.Feedback>

                        </FloatingLabel>
                        <Form.Check
                            style={{margin: 3, marginBottom:0, marginTop:5, color:"grey", fontSize:13}}
                            type="checkbox"
                            label="show password"
                            onChange={()=>setHidePassword(checked => !checked)}
                            checked={!hidePassword}
                        />
                        <Button 
                            variant="warning" 
                            type="submit" 
                            style={{marginTop:10}} 
                            onClick={login}
                        >
                            LOGIN
                        </Button>
                        <Button
                            variant="link"  
                            style={{margin:3, padding:0, fontSize:12, display:"block"}} 
                            onClick={async ()=>{
                                const email = await prompt("Please enter your email address, to receive a password reset email.")
                                if(email && email.length){
                                    resetPassword({email})
                                }
                            }}
                        >
                            Forgot password
                        </Button>
                        <Button
                            variant="link"  
                            style={{margin:0, marginLeft:1, padding:0, fontSize:12, display:"block"}} 
                            onClick={()=>setShowCreateAccount(true)}
                        >
                            Create an account
                        </Button>
                    </Form.Group>
                    <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
                        <div style={{flexDirection:"row", width:"100%", display:"flex", alignItems:"center", marginBottom:10}}>
                            <div style={{flex:1, borderTop:"1px solid grey"}}/>
                            <div style={{marginInline:10, color:"grey"}}>
                                or
                            </div>
                            <div style={{flex:1, borderTop:"1px solid black"}}/>
                        </div>
                        <button 
                            aria-label="Sign in with Microsoft"
                            style={{marginBlock:10, border:"none", padding:0, backgroundColor: "transparent"}}
                            onClick={async ()=>{
                                const success = await loginMicrosoft()
                                if(success){
                                    close()
                                    const email = useStore.getState().userEmail
                                    setTimeout(
                                        ()=>alert("Logged in as " + email + "."),
                                        100
                                    )                
                                } else {
                                    alert("Failed to create account.")
                                }
                            }}
                        >
                            <MS/>
                        </button>
                    </div>
                </Form> 
            </Modal.Body>
        </Modal>
    )
}

const ShareModal = ({link, embed, share, close, open}) => {
    return(
        <Modal 
            show={open} 
            onHide={close} 
            size="xl" 
        >
            <Modal.Header closeButton>
                <div className="modal-title">
                    SHARE THIS MAP
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <textarea readOnly style={{resize:"none", border: "1px solid grey", width:"100%", display:"block", paddingInline:20, paddingBlock:10, color:"grey", borderRadius:6, wordWrap: "break-word"}}>
                        {link}
                    </textarea>
                    <Button                    
                        onClick={() => share(link)}
                        style={{ width: "100%", backgroundColor:"#EEC630", color:"white", marginTop:10, border:"none"}}
                        aria-label="copy url to clipboard"
                        active
                    >
                        <div>
                            COPY URL
                        </div>
                    </Button>
                </div>
                {/* <div>
                    <div style={{border: "1px solid grey", paddingInline:20, paddingBlock:10, color:"grey", borderRadius:6, marginTop:40, wordWrap: "break-word"}}>
                        {embed}
                    </div>
                    <Button                    
                        onClick={()=>share(embed)}
                        style={{ width: "100%", backgroundColor:"#EEC630", color:"white", marginTop:10, border:"none"}}
                        aria-label="copy embed code to clipboard"
                        active
                    >
                        <div>
                            COPY EMBED CODE
                        </div>
                    </Button>
                </div> */}
            </Modal.Body>
        </Modal>
    )
}

const SnapshotModal = ({link, share, close, open}) => {
    return(
        <Modal 
            show={open} 
            onHide={close} 
            size="xl" 
        >
            <Modal.Header closeButton>
                <div className="modal-title">
                    SHARE THIS SNAPSHOT
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <textarea readOnly style={{resize:"none", width:"100%", border: "1px solid grey", display: "block", paddingInline:20, paddingBlock:10, color:"grey", borderRadius:6, wordWrap: "break-word"}}>
                        {link}
                    </textarea>
                    <Button                    
                        onClick={() => share(link)}
                        style={{ width: "100%", backgroundColor:"#EEC630", color:"white", marginTop:10, border:"none"}}
                        aria-label="copy url to clipboard"
                        active
                    >
                        <div>
                            COPY URL
                        </div>
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const Seperator = () => <div style={{width:"95%", borderBottomWidth: "1px", borderBottomColor:"lightgrey", borderBottomStyle:"solid", marginBlock:"8px", marginInline:"auto"}}/>

const MenuButton = React.forwardRef((props, ref) =>
    <li>
        <Button
            ref={ref}
            variant="outline-light"
            onClick={props.onClick}
            style={{ marginLeft: 2, marginRight: 2, borderColor:"white", width: "100%", borderColor: "transparent" }}
            size="sm"
            disabled = {props.active == false}
            tabIndex={0}
        >
            <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                {props.children}
                <div style={{color:"black", marginLeft: "10px"}}>
                    {props.text}
                </div>
            </div>
        </Button>
    </li>
)

function isDescendant(parent, child) {
    var node = child.parentNode;
    while (node != null) {
        if (node == parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

export default Menu
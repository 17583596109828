import ActionBar from "../subcomponents/ActionBar";
import Menu from "../subcomponents/Menu"
import Scratchpad from "../subcomponents/Scratchpad";
import Loading from '../subcomponents/Loading'
import { UserData } from "../subcomponents/UserData";
import { DescribedByTargets } from "../subcomponents/DescribedByTargets";

import { useCallback, useState, useLayoutEffect, useRef, useMemo, useEffect } from "react";
import { useKeypress } from "../../utils/keypress"
import { onDragStop, onDragStart, onDrag, onMouseMove, onMouseExit, onMouseEnter } from "../../mapUtils/dragAndDrop"
import { BsMap, BsMapFill, BsBoundingBoxCircles, BsPlusLg, BsDashLg  } from "react-icons/bs"

import ReactFlow, {
  applyNodeChanges,
  applyEdgeChanges,
  MiniMap,
  Controls,
  getIncomers,
  ControlButton,
  useReactFlow,
  Background,
  getOutgoers,
  getConnectedEdges
} from "react-flow-renderer";

// templates
import EditableTextNode from "../../templates/EditableTextNode";
import CopremiseGroup from "../../templates/CopremiseGroup";
import CustomEdge from "../../templates/CustomEdge";

// map utils
import { autoLayout } from "../../mapUtils/autoLayout";
import {
  getSelectedNode,
  constructNewNode,
  constructNewEdge,
  getDeletionFallout,
  handleSelectionChange
} from "../../mapUtils/mapFunctions";

import { useStore } from "../../utils/store";
import ReactModal from 'react-modal';
import { Alert, Confirm, Prompt } from "../subcomponents/Alert";

import {Toolbar} from 'react-aria-components';

const customEdgeTypes = {
  customEdge: CustomEdge,
};

const miniMapNodeColor = node => {
  if(node.data.argType == "main-contention")
    return "#EEC630"
  else if (node.data.argType.includes("group"))
    return undefined
  else if (node.data.argType.includes("inference"))
    return "white"
  else if (node.data.argType.includes("reason"))
    return "#347004"
  else if (node.data.argType.includes("objection"))
    return "#CD5027"

}

function EditMap({ map, auth }) {
  const { nodes, setNodes, sortedNodes, edges, setEdges, showHelp, setShowHelp, shouldLayout, setShouldLayout, setShouldCache, notesOpen, loading, setLoading, showMiniMap, setShowMiniMap, saved } = map
  const mapInstance = useRef()

  // this is the ref to print .pdf or .png
  const mapRef = useRef(null)

  const show_prompt = useStore(s=>s.prompt.show)
  const show_confirm = useStore(s=>s.confirm.show)
  const show_alert = useStore(s=>s.alert.show)

  useEffect(()=>{
    // warn user if navigating away with unsaved changes.
    // saved is set to true when saving, and to false when caching state for undo's
    if(saved){
      window.onbeforeunload = null // do not warn
    } else {
      window.onbeforeunload = function() {
        return true; // warn
      };
    }
    return () => window.onbeforeunload = null // do not warn
  }, [nodes, saved])

  /* These callback functions make the nodes draggable and edges adapt to the dragging*/
  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nodes) => applyNodeChanges(changes, nodes));
    },
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((edges) => applyEdgeChanges(changes, edges)),
    [setEdges]
  );

  useLayoutEffect(() => {
    if (shouldLayout) {
      // setTimeout(()=> autoLayout(nodes, edges), 100);
      autoLayout(nodes, edges, setNodes, setEdges)
      setShouldLayout(false);
      // console.log(nodes)
      // let ns = [...nodes]
      // ns.sort((a,b)=>a.data?.number > b.data?.number ? 1 : -1)
      // console.log(ns)
    }
  }, [shouldLayout, nodes, edges]);

  // adds a child node as well as an inference node
  const addChildNode = useCallback((nodeType, argType) => {
    const selectedNode = getSelectedNode(nodes);

    // if this evals true, nothing should be done (no node selected)
    if (
      (selectedNode === false) ||
      (selectedNode.data.nodeType == "copremise") // copremise groups cant have children added
    ) {
      return;
    }

    if (argType !== "copremise") {
      // add copremise group
      let newInferenceNode = constructNewNode(nodeType, "inference-" + argType);

      let groupType = argType + '-group';
      let newGroup = constructNewNode(nodeType, groupType);
      let edgeToNewGroup = constructNewEdge(newInferenceNode, newGroup);
      newGroup.style = { height: 0, width: 0 };
      newGroup.type = "copremise";
      newGroup.data.number = "Nan";
      newGroup.data.label = 'CopremiseGroup';
      newGroup.data.nodeType = "copremise";
      newGroup.data.argType = groupType;
      setNodes((nodes) => nodes.concat(newGroup));
      setEdges((edges) => edges.concat(edgeToNewGroup));

      let edgeToNewInference = constructNewEdge(selectedNode, newInferenceNode);
      setNodes((nodes) => nodes.concat(newInferenceNode));
      setEdges((edges) => edges.concat(edgeToNewInference));

      let newNode = constructNewNode(nodeType, argType);
      newNode.parentNode = newGroup.id;
      newNode.selected = true;
      newNode.data.isNewNode = true
      let edgeToNewNode = constructNewEdge(newGroup, newNode);
      edgeToNewNode.hidden = "true";
      setNodes((nodes) => nodes.map(n=>({...n, selected:false})).concat(newNode));
      setEdges((edges) => edges.concat(edgeToNewNode));
    } else {
      // add copremise
      if (selectedNode.data.argType !== 'inference-reason' && selectedNode.data.argType !== 'inference-objection') {
        let parentGroupNode = getIncomers(selectedNode, nodes, edges)[0];
        let newCopremiseNode = constructNewNode(nodeType, argType);
        newCopremiseNode.data.argType = selectedNode.data.argType;
        newCopremiseNode.parentNode = parentGroupNode.id;
        newCopremiseNode.selected = true
        newCopremiseNode.data.isNewNode = true
        let edgeToNewCopremiseNode = constructNewEdge(parentGroupNode, newCopremiseNode);
        edgeToNewCopremiseNode.hidden = "true";
        setNodes((nodes) => nodes.map(n=>({...n, selected:false})).concat(newCopremiseNode));
        setEdges((edges) => edges.concat(edgeToNewCopremiseNode));
      }
    }

    setShouldLayout(true);
    setShouldCache(true)
  }, [nodes, setNodes, edges, setEdges]);

  const deleteCurrentNode = useCallback(() => {
    const selectedNode = getSelectedNode(nodes);
    if (selectedNode === false) return; // nothing is selected
    if (selectedNode.data.argType == "main-contention") return;// dont delete the root

    const [nodesToDelete, edgesToDelete, newSelection] = getDeletionFallout(selectedNode, nodes, edges)

    const nodeIdsToDelete = nodesToDelete.map(node => node.id)
    const edgeIdsToDelete = edgesToDelete.map(edge => edge.id)

    const newNodes = nodes.filter(node => !nodeIdsToDelete.includes(node.id))
    const newEdges = edges.filter(edge => !edgeIdsToDelete.includes(edge.id))

    const toSelect = newNodes.find(n => n.id == newSelection.id)
    if (toSelect) toSelect.selected = true

    setNodes(newNodes)
    setEdges(newEdges)

    setShouldLayout(true)
    setShouldCache(true)
  }, [nodes, setNodes, edges, setEdges])

  const toggleCurrentNode = useCallback(() => {
    const newNodes = [...nodes]
    const toggle = n => n.data = {
      ...n.data,
      argType: n.data.argType == "reason" ? "objection" : 
        n.data.argType == "objection" ? "reason" :
        n.data.argType == "reason-group" ? "objection-group" :
        n.data.argType == "objection-group" ? "reason-group" :
        n.data.argType == "inference-objection" ? "inference-reason" :
        n.data.argType == "inference-reason" ? "inference-objection" :
        "reason-group"
    }
    const selectedNode = getSelectedNode(newNodes);
    if (selectedNode === false) return; // nothing is selected
    if (selectedNode.data.argType == "main-contention") return;// dont toggle the root
    if (selectedNode.data.argType.includes("inference")) return;// dont toggle inferences
    if( selectedNode.parentNode ){
      const parent = getIncomers(selectedNode, newNodes, edges)[0]
      const inference = getIncomers(parent, newNodes, edges)[0]
      const children = getOutgoers(parent, newNodes, edges)
      const connected_edges = getConnectedEdges([parent, inference], edges)
      toggle(parent)
      toggle(inference)
      children.forEach(n=>toggle(n))
      connected_edges.forEach(n=>toggle(n))
    } else {
      const children = getOutgoers(selectedNode, newNodes, edges)
      const inference = getIncomers(selectedNode, newNodes, edges)[0]
      const connected_edges = getConnectedEdges(inference, edges)
      toggle(selectedNode)
      toggle(inference)
      children.forEach(n=>toggle(n))
      connected_edges.forEach(n=>toggle(n))
    }

    setNodes(newNodes)

    setShouldLayout(true)
    setShouldCache(true)
  }, [nodes, setNodes, edges, setEdges])

  useKeypress({ map, setShouldLayout, deleteCurrentNode, addChildNode, toggleCurrentNode })

  const customNodeTypes = useMemo(() => ({
    editableText: EditableTextNode(map),
    copremise: CopremiseGroup,
  }), []);

  const onSelectionChange = useCallback(({nodes: selected})=>{
    const newEdges = [...edges]
    handleSelectionChange(nodes, newEdges, selected[0])
    setEdges(newEdges)
  }, [nodes, edges, setEdges])

  const [isRendering, setIsRendering] = useState(false)

  useEffect(()=>{
    const start = async()=>{
      setLoading(true)
      await new Promise(()=>setTimeout(()=>setLoading(false), 2000))
    }
    start()
  },[])

  const handleFocusMap = useCallback(()=>{
    console.log("f")
    const selected = nodes.filter(n=>n.selected)[0]
    const root = nodes.filter(n=>n.data.argType == "main-contention")[0]
    if(selected){
      selected.data.ref.current.focus()
    } else {
      root.data.ref.current.focus()
    }
  }, [nodes])

  const openModal = useStore(s=>s.openModal)
  const reactFlowInstance = useReactFlow();

  return (
    <div
      style={{ width: "100%", height: "100vh", display: 'flex', flexDirection: "row"}}
      ref={mapRef}
    >
      {loading &&
        <Loading/>
      }
      <div className="top-bar-container">
        <div data-norender>
          <Menu
            map={map}
            auth={auth}
            mapRef={mapRef}
            mapInstance={mapInstance}
            setIsRendering={setIsRendering}
            reactFlowInstance={reactFlowInstance}
          />
        </div>
        <div data-norender>
          <ActionBar
            addChildNode={addChildNode}
            deleteCurrentNode={deleteCurrentNode}
            toggleCurrentNode={toggleCurrentNode}
            map={map}
            auth={auth}
            mapRef={mapRef}
            mapInstance={mapInstance}
          />
        </div>
        <div className="top-menu-right-group">
          <UserData map={map} auth={auth} />
          <Scratchpad auth={auth} map={map} />
        </div>
      </div>
      <div data-norender>
        {show_alert && <Alert/>}
        {show_confirm && <Confirm/>}
        {show_prompt && <Prompt/>}
      </div>
      <DescribedByTargets/>
      <div style={{ flexGrow: 1, height: "100%" }}>
        <ReactFlow
          tabIndex={0}
          // onSelect={()=>console.log("select")}
          // onClick={()=>console.log("select")}
          // onFocus={()=>handleFocusMap()}
          nodes={nodes}
          deleteKeyCode={null}
          edges={edges}
          onSelectionChange={onSelectionChange}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={customNodeTypes}
          edgeTypes={customEdgeTypes}
          id="react-flow-map"
          minZoom={.01}
          onInit={i => mapInstance.current = i}
          onNodeDrag={(event, node) => onDrag({ event, node, setNodes, nodes })}
          onNodeDragStart={(e, n) => onDragStart(e, n, nodes)}
          onNodeDragStop={(event, node) => onDragStop({ event, node, nodes, edges, setEdges, setNodes, setShouldLayout, setShouldCache })}
          proOptions={{ hideAttribution: true }}
          // aria-describedby="nav-instructions"
          role="tree"
        >
          <div data-norender>
            <Background />
            <Controls
              tabIndex = {0}
              showZoom = {false}
              showFitView = {false}
              showInteractive = {false}
              showHelp = {false}
              label = "map controls"
              role = "toolbar"
            >
              <Toolbar aria-label="map toolbar" orientation="vertical">
              {/* zoom in */}
              <ControlButton
                tabIndex={0}
                role="button"
                onClick={()=>reactFlowInstance.zoomIn()}
                aria-label="zoom in"
              >
                <BsPlusLg aria-hidden/>
              </ControlButton>
              {/* zoom out */}
              <ControlButton
                tabIndex={0}
                onClick={()=>reactFlowInstance.zoomOut()}
                aria-label="zoom out"
              >
                <BsDashLg aria-hidden/>
              </ControlButton>
              {/* fit */}
              <ControlButton
                aria-label="fit to screen"
                tabIndex={0}
                onClick={()=>reactFlowInstance.fitView()}
                >
                <BsBoundingBoxCircles aria-hidden/>
              </ControlButton>
              {/* minimap */}
              <ControlButton
                aria-label="toggle mini-map"
                tabIndex={0}
                onClick={()=>setShowMiniMap(show => !show)}
                aria-pressed = { showMiniMap }
              >
                { showMiniMap ? <BsMapFill aria-hidden/> : <BsMap aria-hidden/> }
              </ControlButton>
              </Toolbar>
            </Controls>
            {
              showMiniMap &&
              <MiniMap
                zoomable
                pannable
                nodeColor={miniMapNodeColor}
                tabIndex={-1}
                aria-hidden
              />
            }
          </div>
        </ReactFlow>
      </div>
      {
        (isRendering && notesOpen) && 
        <div className="scratch-pad-filler" />
      }
    </div>
  );
}

export default EditMap;

const horizontalSpacing = 20;
const verticalSpacing = 150;
const originX = 500;
const originY = 100;
// const defaultNodeWidth = 280;
const defaultNodeWidth = 300;
// const defaultNodeWidth = 250;
const defaultNodeHeight = 20;
// const defaultNodeHeight = 90;
const defaultTextareaRows = 2;
const defaultTextareaCols = 25;

export {
  horizontalSpacing,
  verticalSpacing,
  originX,
  originY,
  defaultNodeHeight,
  defaultNodeWidth,
  defaultTextareaRows,
  defaultTextareaCols,
};

import Modal from 'react-bootstrap/Modal';

export const keys = [
    {
        key: "SHIFT + ALT + M",
        action: "Select main contention"
    },
    {
        key: "SHIFT + ALT + R",
        action: "Add new Reason"
    },
    {
        key: "SHIFT + ALT + O",
        action: "Add new Objection"
    },
    {
        key: "SHIFT + ALT + C",
        action: "Add new Co-premise"
    },
    {
        key: "SHIFT + ALT + Enter",
        action: "Enter or exit text edit mode"
    },
    {
        key: "SHIFT + ALT + ↑",
        action: "Select box above"
    },
    {
        key: "SHIFT + ALT + ↓",
        action: "Select box below"
    },
    {
        key: "SHIFT + ALT + →",
        action: "Select box to the right"
    },
    {
        key: "SHIFT + ALT + ←",
        action: "Select box to the left"
    },
    {
        key: "SHIFT + ALT + D",
        action: "Delete node"
    },
    {
        key: "SHIFT + ALT + G",
        action: "Change reason into objection or vice versa"
    },
    {
        key: "SHIFT + ALT + F",
        action: "Select box to move"
    },
    {
        key: "SHIFT + ALT + E",
        action: "Execute move to destination"
    },
    {
        key: "SHIFT + ALT + Escape",
        action: "Cancel move"
    },
    {
        key: "SHIFT + ALT + B",
        action: "Move co-premise left"
    },
    {
        key: "SHIFT + ALT + N",
        action: "Move co-premise right"
    },
    {
        key: "SHIFT + ALT + S",
        action: "Open or close Scratch Pad"
    },
]

export const Help = ({showHelp, setShowHelp}) =>
    <Modal show={showHelp} onHide={()=>setShowHelp(false)} size="lg" >
        <Modal.Header closeButton>
            <div className="modal-title">
                KEY COMMANDS
            </div>
        </Modal.Header>
        <Modal.Body>
            <ul style={{flexDirection:"column", fontFamily:"Poppins", padding:0, margin:0}}>
                {keys.map((k,i)=>
                    <li 
                        style={{width:'100%', display:"flex", flexDirection:"row"}}
                        key={k.key}
                    >
                        <div style={{marginRight:"auto"}}>{k.action}</div>
                        <div style={{marginLeft:20, flexShrink:0}}>{k.key}</div>
                    </li>
                )}
            </ul>
        </Modal.Body>
    </Modal>
export const UserData = ({map, auth}) => {
    // console.log("map")
    // console.log(map)
    return(
        <div className="user-data">
            <div className="user-name">
                {auth.email || "anonymous"}
            </div>
            <div className="map-name">
                <div style={{marginRight:10}}>
                    {map.name.slice(0,46) || "untitled"}
                </div>
            </div>
            <div style={{marginLeft:'auto'}}>
                {"updated " + toDateString(map.lastUpdated)}
            </div>
        </div>
    )
}

const toDateString = x => x ? new Date(x).toJSON().slice(2,10).split('-').reverse().join('/') : "N/A"
import { useEffect, useCallback } from 'react';
import { getOutgoers } from 'react-flow-renderer';
import { keypressMove, keypressShiftLeft, keypressShiftRight } from '../mapUtils/dragAndDrop';
import { getSelectedNode, getParent, getFirstChild, getNodesWithPosition } from "../mapUtils/mapFunctions";
import { defaultNodeHeight } from '../mapUtils/mapConstants';
import { handleSetMoveSource } from '../mapUtils/dragAndDrop';
/*

m           - select main contention
r           - add reason node
o           - add objection
c           - add copremise
d           - delete node
f           - select move source
t           - execute move
b           - move copremise left
n           - move copremise right
s           - open scratch pad
upArrow     - parent
downArrow   - first child
rightArrow  - next sibling
leftArrow   - previous sibling

*/

export function useKeypress({map, setShouldLayout, deleteCurrentNode, addChildNode, toggleCurrentNode}) {

    const { nodes, setNodes, edges, setEdges, name, setName, mapId, setMapId, undo, redo, moveSource, setMoveSource, setShouldCache, setNotesOpen, notesOpen } = map

    const onKeyPress = useCallback(e=>{
        // console.log(e.code, e.shiftKey, e.altKey)
        if(e.metaKey && (e.code == "KeyZ")){
            e.shiftKey ? redo() : undo()
        }
        if(e.ctrlKey && (e.code == "KeyZ")){
            undo()
        }
        if(e.ctrlKey && (e.code == "KeyY")){
            redo()
        }
        // if(e.code == "Backspace"){
        //     e.preventDefault()
        //     deleteCurrentNode()
        // }
        if(!e.shiftKey || !e.altKey) return;
        switch(e.code){
            case "Enter":{
                e.preventDefault()
                // console.log("enter")
                const selected = getSelectedNode(nodes)
                // check if it exists and is a textInput
                if(!selected || !selected.data || !selected.data.textInputRef || !selected.data.textInputRef.current) return;
                const textInput = selected.data.textInputRef.current
                if(textInput === document.activeElement){
                    textInput.blur()
                } else {
                    textInput.focus()
                }
                break;
            }
            case "Escape":{
                e.preventDefault()
                console.log("clear drag source")
                setMoveSource(undefined)
                handleSetMoveSource({source:undefined, nodes, edges, setNodes})
                setShouldLayout(true)
                break;
            }
            case "ArrowUp":{
                e.preventDefault()
                // console.log("up")
                const selected = getSelectedNode(nodes)
                if(!selected) return;
                const parent = getParent( selected, nodes, edges )
                if(!parent) return;
                parent.selected = true
                selected.selected = false
                setNodes(nodes)
                setShouldLayout(true)
                if(parent.data.ref && parent.data.ref.current){
                    parent.data.ref.current.focus()
                }
                break;
            }
            case "ArrowDown":{
                e.preventDefault()
                // console.log("down")
                const selected = getSelectedNode(nodes)
                if(!selected) return;
                const child = getFirstChild(selected, nodes, edges)
                if(!child) return;
                child.selected = true
                selected.selected = false
                setNodes(nodes)
                setShouldLayout(true)
                if(child.data.ref && child.data.ref.current){
                    child.data.ref.current.focus()
                }
                break;
            }
            case "ArrowLeft":{
                e.preventDefault()
                const selected = getSelectedNode(nodes)
                const rank = selected.data.rank
                const siblingsRaw = nodes.filter(x=>x.data.rank == rank)
                const siblings = getNodesWithPosition(siblingsRaw)
                const sortedSiblings = siblings.sort((a,b)=>a.position.x - b.position.x)
                const indexOfSelected = sortedSiblings.findIndex(n=>n.id == selected.id)
                if(indexOfSelected == 0)return(console.log("its the first sibling"));
                const prevId = sortedSiblings[indexOfSelected - 1].id
                const prev = nodes.filter(n=>n.id == prevId)[0]
                prev.selected = true
                selected.selected = false
                if(prev.data.ref && prev.data.ref.current){
                    prev.data.ref.current.focus()
                }
                break;
            }
            case "ArrowRight":{
                e.preventDefault()
                // console.log("right")
                const selected = getSelectedNode(nodes)
                const rank = selected.data.rank
                const siblingsRaw = nodes.filter(x=>x.data.rank == rank)
                const siblings = getNodesWithPosition(siblingsRaw)
                const sortedSiblings = siblings.sort((a,b)=>a.position.x - b.position.x)
                const indexOfSelected = sortedSiblings.findIndex(n=>n.id == selected.id)
                if(indexOfSelected >= (sortedSiblings.length - 1))return(console.log("its the last sibling"));
                const nextId = sortedSiblings[indexOfSelected + 1].id
                const next = nodes.filter(n=>n.id == nextId)[0]
                next.selected = true
                selected.selected = false
                if(next.data.ref && next.data.ref.current){
                    next.data.ref.current.focus()
                }
                break;
            }
            case "KeyD":{ // delete node
                e.preventDefault()
                // console.log("d")
                deleteCurrentNode()
                break;
            }
            case "KeyM":{ // select main contention
                e.preventDefault()
                // console.log("r")
                const node = nodes.find(n=>n.data.argType == "main-contention")
                const selected = getSelectedNode(nodes)
                if(selected)selected.selected = false
                if(node)node.selected = true
                if(node.data.ref && node.data.ref.current){
                    node.data.ref.current.focus()
                }
                break;        
            }
            case "KeyR":{ // add reason
                e.preventDefault()
                // console.log("A")
                addChildNode("editableText", "reason")
                break;
            }
            case "KeyO":{ // add objection
                e.preventDefault()
                // console.log("A")
                addChildNode("editableText", "objection")
                break;
            }
            case "KeyC":{ // add copremise
                e.preventDefault()
                // console.log("A")
                addChildNode("editableText", "copremise")
                break;
            }
            case "KeyF":{ // select move source
                e.preventDefault()
                // console.log("A")
                const source = getSelectedNode(nodes)
                setMoveSource(source)
                handleSetMoveSource({source, nodes, edges, setNodes})
                setShouldLayout(true)
                console.log("select source and rerender")
                // setNodes([...nodes])
                break;
            }
            case "KeyE":{ // execute move
                e.preventDefault()
                const target = getSelectedNode(nodes)
                keypressMove({nodes, edges, moveSource, target, setNodes, setEdges, setShouldCache, setShouldLayout})
                console.log("select target")
                break
            }
            case "KeyB":{ // shift copmremise left
                e.preventDefault()
                keypressShiftLeft({nodes, edges, setNodes, setShouldCache, setShouldLayout})
                break
            }
            case "KeyN":{ // shift copremise right
                e.preventDefault()
                keypressShiftRight({nodes, edges, setNodes, setShouldCache, setShouldLayout})
                break
            }
            case "KeyS":{ // shift copremise right
                e.preventDefault()
                setNotesOpen(!notesOpen)
                break
            }
            case "KeyG":{ // shift copremise right
                e.preventDefault()
                toggleCurrentNode()
                break
            }
        }
    },[map, setShouldLayout, deleteCurrentNode, addChildNode, nodes, setNodes, edges, setEdges, name, setName, mapId, setMapId])

    useEffect(()=>{
        document.addEventListener("keydown", onKeyPress)
        return ()=>document.removeEventListener("keydown", onKeyPress)
    },[map, setShouldLayout, deleteCurrentNode, addChildNode, nodes, setNodes, edges, setEdges, name, setName, mapId, setMapId])
}
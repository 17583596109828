import { useRef, useEffect } from "react";
import { Handle, Position } from "react-flow-renderer";

function CopremiseGroup({ data }) {
  const ref = useRef()

  useEffect(() => {
    data.ref = ref
  }, [])

  const numbering = `${data.rank}.${data.order + 1}${data.position ? ("." + (data.position + 1)) : ""}`
  const isDraggableNode = (data.argType.includes("inference") || data.argType == "main-contention") == false

  return (
    <div
      ref={ref}
      id={data.id}
      className={`${data.nodeType}-${data.argType} ${data.target ? "target" : ""}`}
      aria-label={ numbering + " claim group"}
      tabIndex={-1}
      // aria-describedby={`${isDraggableNode ? "draggable" : "non-draggable"} ${data.dropable ? "dropable" : "non-dropable"}`}
    >
      <Handle type="target" position={Position.Top}></Handle>
      <div style={{fontSize:12, paddingLeft:4}}>{numbering}</div>
      <Handle type="source" position={Position.Bottom} style={{display:"none"}}/>
    </div>
  );
}

export default CopremiseGroup;

const accessibility_html = `
<h2>Accessibility Statement for <span class="basic-information website-name">Argumentation</span></h2>
<p>
	This is an accessibility statement from <span class="basic-information organization-name">Argumentation LLC</span>.
</p>
<h3>Measures to support accessibility</h3>
<p>
	<span class="basic-information organization-name">Argumentation LLC</span>
	takes the following measures to ensure accessibility of
	<span class="basic-information website-name">Argumentation</span>:
</p>
<ul class="organizational-effort accessibility-measures">
	<li>Include accessibility throughout our internal policies.</li>
	<li>Provide continual accessibility training for our staff.</li>
	<li>Assign clear accessibility goals and responsibilities.</li>
	<li>Employ formal accessibility quality assurance methods.</li>
	<li>Consult with people with disabilities</li>
</ul>
<h3>Conformance status</h3>
<p>
	The <a href="https://www.w3.org/WAI/standards-guidelines/wcag/">Web Content Accessibility Guidelines (WCAG)</a> defines requirements for designers and developers to improve accessibility for people with disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA.
	<span class="basic-information website-name">Argumentation</span>
	is
	<span class="basic-information conformance-status" data-printfilter="lowercase">partially conformant</span>
	with
	<span class="basic-information conformance-standard"><span>WCAG 2.2 level AA</span>.</span>
	<span>
	<span class="basic-information conformance-status">Partially conformant</span>
	means that
	<span class="basic-information conformance-meaning">some parts of the content do not fully conform to the accessibility standard</span>.
</span>
</p>
<h3>Feedback</h3>
<p>
	We welcome your feedback on the accessibility of
	<span class="basic-information website-name">Argumentation</span>.
	Please let us know if you encounter accessibility barriers on
	<span class="basic-information website-name">Argumentation</span>:
</p>
<ul class="basic-information feedback h-card">
	<li>
	E-mail:
	<a class="email u-email" href="mailto:argumentationcompany@gmail.com">argumentationcompany@gmail.com</a>
</li>
</ul>
<h3>Compatibility with browsers and assistive technology</h3>
<p>
	<span class="basic-information website-name">Argumentation</span>
	is designed to be compatible with the following assistive technologies:
</p>
<ul class="technical-information compatible-environments">
	<li>Argumentation works with browsers Chrome, Firefox, Edge, and Safari; with screen readers NVDA, Jaws, and VoiceOver; on operating systems Windows and Mac OS.</li>
</ul>
<h3>Technical specifications</h3>
<p>
	Accessibility of
	<span class="basic-information website-name">Argumentation</span>
	relies on the following technologies to work with the particular combination of web browser and any assistive technologies or plugins installed on your computer:
</p>
<ul class="technical-information technologies-used">
	<li>HTML</li>
</ul>
<p>These technologies are relied upon for conformance with the accessibility standards used.</p>
<h3>Limitations and alternatives</h3>
<p>
	Despite our best efforts to ensure accessibility of
	<span class="basic-information website-name">Argumentation</span> , there may be some limitations. Below is a description of known limitations, and potential solutions. Please contact us if you observe an issue not listed below.
</p>
<p>
	Known limitations for
	<span class="basic-information website-name">Argumentation</span>:
</p>
<ol class="technical-information accessibility-limitations">
	<li><strong>Drag and drop</strong>: Drag and drop is mostly but not completely operable through key commands. Key commands can select a diagrammatic element, navigate to a “drop target,” and move the selected element to the target position. However, at present, screen readers do not indicate valid or invalid “drop targets." Highlighting indicates this for mouse users. because . We are working on adding features to enable screen readers to identify valid "drop targets.". Screen reader users can use drag and drop without identification of valid drop targets..</li>
	<li><strong>Subscriptions</strong>: The subscription component takes the user to our Stripe payment site. Stripe does not meet all WCAG 2.2 criteria. It does not make proper use of labels and instructions, has some text that does not adequately contrast with its background, and its headings and labels do not describe their topics or purposes. because Stripe is an independent site.. . The issue will not affect users who subscribe through their institution..</li>
</ol>
<h3>Assessment approach</h3>
<p>
	<span class="basic-information organization-name">Argumentation LLC</span>
	assessed the accessibility of
	<span class="basic-information website-name">Argumentation</span>
	by the following approaches:
</p>
<ul class="technical-information assessment-approaches">
	<li>Self-evaluation</li>
	<li>External evaluation</li>
	<li>Monthly automated accessibility tests</li>
</ul>
<h3>Formal complaints</h3>
<p class="complaints">If you have an accessibility-related complaint, please write to argumentationcompany@gmail.com.</p>
<h3>Formal approval of this accessibility statement</h3>
<p>This Accessibility Statement is approved by:</p>
<p class="approval">
	<span class="basic-information organization-name">Argumentation LLC</span><br>
	<span class="approved-by">Jonathan Surovell</span><br>
	<span class="approved-by-jobtitle">Chief Operating Officer</span>
</p>
<hr noshade="noshade">
<h3>Date</h3>
<p>
	This statement was created on
	<span class="basic-information statement-created-date">12 April 2024</span>
	using the <a href="https://www.w3.org/WAI/planning/statements/">W3C Accessibility Statement Generator Tool</a>.
</p>
`

export const Accessibility = () => <div dangerouslySetInnerHTML={{ __html: accessibility_html }} />
import { getConnectedEdges, getIncomers, getOutgoers } from "react-flow-renderer"
import { constructNewNode, constructNewEdge, getSelectedNode } from './mapFunctions'

let dragged = undefined
let target = undefined
let ogNodes = undefined

export const onMouseEnter = ({event, node, nodes}) => {
    // console.log("enter " + node.id)
    // const targets = getNodesAt(node, nodes)
    // setTarget(node.id)
    // target = node
}

export const onMouseExit = ({event, node}) => {
    // console.log("exit " + node.id)
    // setTarget(null)
    // target = undefined
}

export const onMouseMove = (event, node ) => {

}

export const onDrag = ({event, node, nodes, setNodes}) => {
    const targets = getNodesAt(node, nodes)
    if(!targets.length){ // nothing there
        setTarget(null, setNodes)
        return
    }
    if(targets.length == 1){ // only one
        // console.log("over " + targets[0].id)
        setTarget(targets[0].id, setNodes)
        return
    }
    // more then one, prefer children
    const copremise = targets.find(t=>t.parentNode != undefined)
    if(copremise != undefined){
        // console.log("over " + copremise.id)
        setTarget(copremise.id, setNodes)
        return
    }
    // unusual case, just set first
    // console.log("over " + targets[0].id)
    setTarget(targets[0].id, setNodes)
}

export const onDragStart = (event, node, nodes) => {
    dragged = {...node} // cache the node
    ogNodes = [...nodes].map(x=>({...x})) // cache the nodes
}

export const onDragStop = ({event, node, nodes, edges, setEdges, setNodes, setShouldLayout, setShouldCache}) => {
    console.log("drop " + node.id)
    const targets = getNodesAt(node, nodes)
    console.log(targets)

    if(isChild(node)){
        if(targets.some(t=>(t.parentNode != undefined) || (t.data.argType == "main-contention"))){
             // its on a copremise or the main contention
            const target = targets.find(t=>(t.parentNode != undefined) || (t.data.argType == "main-contention"))
            moveToNode({node, nodes, edges, setNodes, setEdges, setShouldLayout, target, setShouldCache})
        } else if(targets.some(t=>t.type == "copremise")){ 
            // its on a group
            if(targets.find(t=>t.id == node.parentNode) != undefined){
                reorderGroup({node, nodes, edges, setNodes, setShouldLayout, setShouldCache})
            } else {
                const target = targets.find(t=>t.type == "copremise")
                moveToGroup({node, nodes, edges, setNodes, setEdges, setShouldLayout, setShouldCache, target})
            }
        } else if(targets.some(t=>t.data.argType.includes("inference"))){ 
            // its on an inference
            const target = targets.find(t=>t.data.argType.includes("inference"))
            moveToInference({node, nodes, edges, setEdges, setNodes, setShouldLayout, setShouldCache, target})
        } else {
            // dropped nowhere
            setShouldLayout(true)
        }
    } else {
        if(targets.some(t=>t.parentNode != undefined)){
            // dropped a group on a copremise
            const target = targets.find(t=>t.parentNode != undefined)
            moveGroup({node, nodes, edges, setEdges, setNodes, setShouldLayout, setShouldCache, target})
        } else {
            setShouldLayout(true)
        }
    }
    dragged = undefined
    setTarget(null, setNodes)

}

export const keypressMove = ({nodes, edges, moveSource, target, setNodes, setEdges, setShouldCache, setShouldLayout}) => {
    if(isChild(moveSource)){
        // dragging a copremise
        if(isChild(target) || isMainContention(target)){
            // drop on a copremise or main contention
            moveToNode({node: moveSource, nodes, edges, setNodes, setEdges, setShouldLayout, target, setShouldCache})
        } else if(isGroup(target)){
            // drop on a group
            moveToGroup({node: moveSource, nodes, edges, setNodes, setEdges, setShouldLayout, setShouldCache, target})
        } else if(isInference(target)){
            // drop on an inference
            moveToInference({node: moveSource, nodes, edges, setEdges, setNodes, setShouldLayout, setShouldCache, target})
        } else {
            console.log("unknown move target")
        }
    } else if(isGroup(moveSource)){
        // moving a group
        if(isChild(target) || isMainContention(target)){
            // drop on a group or main contention
            moveGroup({node: moveSource, nodes, edges, setEdges, setNodes, setShouldLayout, setShouldCache, target})
        } else {
            console.log("unknown move target")
        }
    } else {
        console.log("unknown move source")
    }
}

export const keypressShiftLeft = ({nodes, edges, setNodes, setShouldCache, setShouldLayout}) => {
    const node = getSelectedNode(nodes)
    if(!isChild(node)){
        console.log("not a child")
        return
    }
    const siblings = getSiblings(node, nodes, edges)
    const position = siblings.findIndex(n=>n.id == node.id)
    if(position <= 0){
        console.log("cant move")
        return
    }
    const newNodes = [...nodes].map(n=>({...n})) // cache the nodes
    const newPosition = position - 1
    const targetNode = siblings[newPosition]
    const sourceIndex = nodes.findIndex(n=>n.id == node.id)
    const targetIndex = nodes.findIndex(n=>n.id == targetNode.id)
    // do the swap
    newNodes[sourceIndex] = targetNode
    newNodes[targetIndex] = node
    setNodes(newNodes)
    setShouldCache(true)
    setShouldLayout(true)

}

export const keypressShiftRight = ({nodes, edges, setNodes, setShouldCache, setShouldLayout}) => {
    const node = getSelectedNode(nodes)
    if(!isChild(node)){
        console.log("not a child")
        return
    }
    const siblings = getSiblings(node, nodes, edges)
    const position = siblings.findIndex(n=>n.id == node.id)
    const numSiblings = siblings.length
    if(position >= (numSiblings - 1)){
        console.log("cant move")
        return
    }
    const newNodes = [...nodes].map(n=>({...n})) // cache the nodes
    const newPosition = position + 1
    const targetNode = siblings[newPosition]
    const sourceIndex = nodes.findIndex(n=>n.id == node.id)
    const targetIndex = nodes.findIndex(n=>n.id == targetNode.id)
    // do the swap
    newNodes[sourceIndex] = targetNode
    newNodes[targetIndex] = node
    setNodes(newNodes)
    setShouldCache(true)
    setShouldLayout(true)
}

const getNodesAt = (node, nodes)=> {
    let x, y
    let children = []
    if(isChild(node)){
        const parent = getParent(node, nodes)
        // get absolute centers
        x = node.position.x + parent.position.x + node.width / 2
        y = node.position.y + parent.position.y + node.height / 2 
    } else {
        x = node.position.x + node.width / 2
        y = node.position.y + node.height / 2
    }
    // return whats there
    return nodes.filter(n=>{
        let nx, ny
        if(isChild(n)){
            const nparent = getParent(n, nodes)
            nx = n.position.x + nparent.position.x
            ny = n.position.y + nparent.position.y
        } else {
            nx = n.position.x
            ny = n.position.y
        }
        return (
            n.id != node.id &&         // its not myself
            n.parentNode != node.id && // its not my child
            (nx < x) &&
            ((nx + n.width) > x ) &&
            (ny < y) &&
            ((ny + n.height) > y)
        )
    })
}

const reorderGroup = ({node, nodes, edges, setNodes, setShouldLayout, setShouldCache}) => {
    const sibs = getSiblings(node, nodes, edges)
    const newNodes = [...nodes].map(n=>({...n})) // cache the nodes
    
    const ogSibs = sibs.map(s=>ogNodes.find(x=>x.id == s.id))
    const ogSibsOrdered = ogSibs.sort((a,b)=>a.position.x - b.position.x)
    const ogSibsWithIndex = ogSibsOrdered.map(s=>({
        ...s,
        index: nodes.findIndex(n=>n.id == s.id)
    }))

    const sibsOrdered = sibs.sort((a,b)=>a.position.x - b.position.x)

    sibsOrdered.forEach((n, i)=>{
        const oldOccupant = ogSibsWithIndex[i]
        newNodes[oldOccupant.index] = n
    })

    setNodes(newNodes)
    setShouldLayout(true)
    setShouldCache(true)
}

const moveToGroup = ({node, nodes, edges, setNodes, setEdges, setShouldLayout, setShouldCache, target}) => {
    const parent = getParent(node, nodes)
    const siblings = getSiblings(node, nodes, edges)
    const inference = getIncomers(parent, nodes, edges)[0]
    let newNodes = [...nodes];
    let newEdges = [...edges];
    let edgesToDelete = []
    if(siblings.length == 1){
        // check that it wont break the graph
        const getDescendents = node => getOutgoers(node, nodes, edges).reduce((acc, cur)=> 
            [ ...acc, cur, ...(getDescendents(cur)) ], [])
        const descendents = getDescendents(inference)
        if(descendents.map(n=>n.id).includes(target.id)){
            console.log("illegal move")
            setShouldLayout(true)
            return
        }
        // delete the group and inference
        newNodes = newNodes.filter(n=>(n.id != parent.id) && (n.id != inference.id) ) // erase them
        edgesToDelete = getConnectedEdges([parent, inference], newEdges)
    } else  {
        const edge = newEdges.find(e=>e.target == node.id)
        edgesToDelete.push(edge)
    }
    // delete uneeded edges
    edgesToDelete = edgesToDelete.map(e=>e.id)
    newEdges = newEdges.filter(e=>!edgesToDelete.includes(e.id))

    // const nodeIndex = newNodes.findIndex(n=>n.id == node.id)
    // newNodes[nodeIndex].parentNode = target.id
    const newNode = newNodes.find(n=>n.id == node.id)
    newNode.parentNode = target.id
    // take on the color of the new parent
    newNode.data.argType = target.data.argType == "reason-group" ? "reason" : "objection"

    // remove the old place
    newNodes = newNodes.filter(n=>n.id != newNode.id)

    // find the last child
    const newSiblings = getOutgoers(target, nodes, edges).sort((a,b)=>a.position.y - b.position.y)
    const lastSibling = newSiblings[newSiblings.length -1]
    const lastSiblingIndex = newNodes.findIndex(n=>n.id == lastSibling.id)

    // splice it in
    newNodes.splice(lastSiblingIndex + 1, 0, newNode)

    // add a new edge for it
    newEdges.push({
        id: `e${target.id}-${node.id}`, 
        source: target.id, 
        target: node.id,
        hidden: true
    })

    setNodes(newNodes)
    setEdges(newEdges)
    setShouldLayout(true)
    setShouldCache(true)
}

const moveToNode = ({node, nodes, edges, setNodes, setEdges, setShouldLayout, setShouldCache, target}) => {
    const parent = getParent(node, nodes)
    const siblings = getSiblings(node, nodes, edges)
    const inference = getIncomers(parent, nodes, edges)[0]
    let newNodes = [...nodes];
    let newEdges = [...edges];
    let edgesToDelete = []
    if(siblings.length == 1){
        // take the inference and the group
        // check that it wont break the graph
        const getDescendents = node => getOutgoers(node, nodes, edges).reduce((acc, cur)=> 
            [ ...acc, cur, ...(getDescendents(cur)) ], [])
        const descendents = getDescendents(inference)
        if(descendents.map(n=>n.id).includes(target.id)){
            console.log("illegal move")
            setShouldLayout(true)
            return
        }
    
        const edgeIndex = newEdges.findIndex(e=>e.target == inference.id)
        newEdges[edgeIndex].source = target.id
    } else {
        // console.log("has siblings")
        // leave the inference and the group
        // remove the node
        newNodes = newNodes.filter(n=>n.id != node.id)
        // delete its imput edges
        const edge = newEdges.find(e=>e.target == node.id)
        edgesToDelete.push(edge)

        //create a new group and inference
        const nodeType = node.data.nodeType
        const argType = node.data.argType

        let newInferenceNode = constructNewNode(nodeType, "inference-" + argType);
        let groupType = argType + '-group';
        let newGroup = constructNewNode(nodeType, groupType);
        let edgeToNewGroup = constructNewEdge(newInferenceNode, newGroup);
        newGroup.style = { height: 0, width: 0 };
        newGroup.type = "copremise";
        newGroup.data = { label: 'Copremise Group', nodeType: "copremise", argType: groupType }
  
        let edgeToNewInference = constructNewEdge(target, newInferenceNode);
  
        node.parentNode = newGroup.id;
        let edgeToNewNode = constructNewEdge(newGroup, node);
        edgeToNewNode.hidden = "true";

        newNodes = [...newNodes, newInferenceNode, newGroup, node]
        newEdges = [...newEdges, edgeToNewInference, edgeToNewGroup, edgeToNewNode]
      }
    // delete uneeded edges
    edgesToDelete = edgesToDelete.map(e=>e.id)
    newEdges = newEdges.filter(e=>!edgesToDelete.includes(e.id))

    setNodes(newNodes)
    setEdges(newEdges)
    setShouldLayout(true)
    setShouldCache(true)
}

const moveToInference = ({node, nodes, edges, setNodes, setEdges, setShouldLayout, setShouldCache, target}) => {
    const parent = getParent(node, nodes)
    const siblings = getOutgoers(parent, nodes, edges)
    const inference = getIncomers(parent, nodes, edges)[0]
    let newEdges = [...edges]
    let newNodes = [...nodes]
    const inferenceIndex = edges.findIndex(e=>e.target == inference.id)
    if(siblings.length == 1){
        // it is an only child so we can just move it
        // check that its not dragging onto its own inference
        if(target.id == inference.id){
            console.log("illegal move")
            setShouldLayout(true)
            return
        }
        // check that it's not going to break the graph
        const getDescendents = node => getOutgoers(node, nodes, edges).reduce((acc, cur)=> 
            [ ...acc, cur, ...(getDescendents(cur)) ], [])
        const descendents = getDescendents(inference)
        if(descendents.map(n=>n.id).includes(target.id)){
            console.log("illegal move")
            setShouldLayout(true)
            return
        }

        newEdges[inferenceIndex].source = target.id
    } else {
        // it has siblings so we need to construct a new graph for it, and erase the old stuff
        const nodeType = node.data.nodeType
        const argType = node.data.argType

        let newInferenceNode = constructNewNode(nodeType, "inference-" + argType);
        let groupType = argType + '-group';
        let newGroup = constructNewNode(nodeType, groupType);
        let edgeToNewGroup = constructNewEdge(newInferenceNode, newGroup);
        newGroup.style = { height: 0, width: 0 };
        newGroup.type = "copremise";
        newGroup.data = { label: 'Copremise Group', nodeType: "copremise", argType: groupType }
  
        let edgeToNewInference = constructNewEdge(target, newInferenceNode);
  
        node.parentNode = newGroup.id;
        let edgeToNewNode = constructNewEdge(newGroup, node);
        edgeToNewNode.hidden = "true";

        // remove the old node and edge
        newEdges = newEdges.filter(e=>e.target != node.id)
        newNodes = newNodes.filter(n=>n.id != node.id)

        // add the new family
        newEdges = newEdges.concat([edgeToNewGroup, edgeToNewInference, edgeToNewNode])
        newNodes = newNodes.concat([newGroup, newInferenceNode, node])

    }
    setEdges(newEdges)
    setNodes(newNodes)
    setShouldLayout(true)
    setShouldCache(true)
}

const moveGroup = ({node, nodes, edges, setEdges, setNodes, setShouldLayout, setShouldCache, target}) => {
    console.log("move group")
    // check that its not moving to its own descendant
    const getDescendents = node => getOutgoers(node, nodes, edges).reduce((acc, cur)=> 
        [ ...acc, cur, ...(getDescendents(cur)) ], [])
    const descendents = getDescendents(node)
    if(descendents.map(n=>n.id).includes(target.id)){
        console.log("illegal move")
        setShouldLayout(true)
        return
    }
    // just change the edge
    let newEdges = [...edges]
    const inference = getIncomers(node, nodes, edges)[0]
    const edgeIndex = edges.findIndex(e=>e.target == inference.id)
    newEdges[edgeIndex] = {
        ...newEdges[edgeIndex],
        source: target.id,
        id: `e${target.id}-${node.id}`
    }
    setEdges(newEdges)
    setShouldLayout(true)
    setShouldCache(true)
}

const setTarget = (id, setNodes) => {
    setNodes(nodes => nodes.map(n=>({
        ...n,
        data: {
            ...n.data,
            target: n.id == id
        }
    })))
}

export const isValidDropTarget = (source, target, nodes, edges) => {
    if(source.id == target.id) return false;
    if(isChild(source)){
        // dragging a copremise
        if(isChild(target) || isMainContention(target) || isGroup(target)){
            // drop on a copremise or main contention
            const siblings = getSiblings(source, nodes, edges)
            const parent = getParent(source, nodes)
            const inference = getIncomers(parent, nodes, edges)[0]

            if(siblings.length == 1){
                // take the inference and the group
                // check that it wont break the graph
                const getDescendents = node => getOutgoers(node, nodes, edges).reduce((acc, cur)=> 
                    [ ...acc, cur, ...(getDescendents(cur)) ], [])
                const descendents = getDescendents(inference)
                if(descendents.map(n=>n.id).includes(target.id)){
                    return false
                }
            }
            return true
        } else if(isInference(target)){
            // drop on an inference
            const siblings = getSiblings(source, nodes, edges)
            const parent = getParent(source, nodes)
            const inference = getIncomers(parent, nodes, edges)[0]
            if(target.id == inference.id){
                return false
            }
            if(siblings.length == 1){
                // take the inference and the group
                // check that it wont break the graph
                const getDescendents = node => getOutgoers(node, nodes, edges).reduce((acc, cur)=> 
                    [ ...acc, cur, ...(getDescendents(cur)) ], [])
                const descendents = getDescendents(inference)
                if(descendents.map(n=>n.id).includes(target.id)){
                    return false
                }
            }
            return true
        } else {
            console.log("unknown move target")
            return false
        }
    } else if(isGroup(source)){
        // moving a group
        if(isChild(target) || isMainContention(target)){
            // drop on a group or main contention
            const getDescendents = node => getOutgoers(node, nodes, edges).reduce((acc, cur)=> 
                [ ...acc, cur, ...(getDescendents(cur)) ], [])
            const descendents = getDescendents(source)
            if(descendents.map(n=>n.id).includes(target.id)){
                return false
            }
            return true
        } else {
            return false
            console.log("unknown move target")
        }
    } else {
        return false
        console.log("unknown move source")
    }

}

export const handleSetMoveSource = ({source, edges, setNodes}) => {
    setNodes(nodes=>nodes.map(node=>({
        ...node,
        data: {
            ...node.data,
            dropable: source == undefined ? undefined : isValidDropTarget(source, node, nodes, edges) ? true : false
        }
    })))
}

const isInference = node => node.data.argType.includes("inference")
const isGroup = node => node.type == "copremise"
const isChild = node => node.parentNode != undefined
const isMainContention = node => node.data.argType == "main-contention"
const getParent = (node, nodes) => nodes.find(n=>n.id == node.parentNode)
const getSiblings = (node, nodes, edges) => getOutgoers(getParent(node, nodes), nodes, edges)
import domtoimage from 'dom-to-image-more';
import { jsPDF } from "jspdf";

function filter(node){
    if(node.hasAttribute){
        if(node.hasAttribute("data-norender")){
            return false
        }
        return true
    }
    return true
}

export const downloadJPEG = async (ref) => {
    const element = ref.current;
    const dataUrl = await domtoimage.toJpeg(element, 
        {
            quality: 1.0, 
            bgcolor: "white",
            filter: filter
        }
    )
    var link = document.createElement('a');
    link.download = 'my-image-name.jpeg';
    link.href = dataUrl;
    link.click();
}

export const downloadPdf = async ({mapId, name}) => {
    await fetch('/renderPDF', {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({mapId, name})
    })
    .then(response => {
        if(response.ok){
            return response.body
        } else {
            throw new Error("Server error")
        }
    })
    .then(body => {
        const reader = body.getReader();
        return new ReadableStream({
            start(controller) {
                return pump();
                function pump() {
                    return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }
                        controller.enqueue(value);
                        return pump();
                    })
                }
            }
        })
    })
    .then(stream => new Response(stream))
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .then(url => {
        const link = document.createElement('a');
        link.href = url;
        link.download = name + ".pdf";
        link.click();
    })
    .catch(err => {
        console.log(err)
        alert("Server error, please try again.")
    });
}

export const downloadPng = async ({mapId, name}) => {
    await fetch('/renderPng', {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({mapId, name})
    })
    .then(response => {
        if(response.ok){
            return response.body
        } else {
            throw new Error("Server error")
        }
    })
    .then(body => {
        const reader = body.getReader();
        return new ReadableStream({
            start(controller) {
                return pump();
                function pump() {
                    return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }
                        controller.enqueue(value);
                        return pump();
                    })
                }
            }
        })
    })
    .then(stream => new Response(stream))
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .then(url => {
        const link = document.createElement('a');
        link.href = url;
        link.download = name + ".png";
        link.click();
    })
    .catch(err => {
        console.log(err)
        alert("Server error, please try again.")
    });
}

export const renderPngToUrl = async ({mapId, name}) => {
    try {
        // name = name.replace(/[!'()*]/g, "")
        const res = await fetch('/renderPngToUrl', {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({mapId, name})
        })

        console.log(res)
        
        const json = await res.json()
        console.log(json)
        return json.url
        
    } catch(err) {
        console.log(err)
        alert("Server error, please try again.")
    }
}
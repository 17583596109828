import { getConnectedEdges, getIncomers, getOutgoers } from "react-flow-renderer";
import { v4 as uuidv4 } from "uuid";
import {
  defaultNodeWidth,
  defaultNodeHeight,
  defaultTextareaRows,
  defaultTextareaCols,
} from "./mapConstants.js";

/*

argTypes:
reason | objection | copremise | reason-group | objection-group | inference-reason | inference-objection

*/

function getSelectedNode(nodes) {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].selected === true) {
      return nodes[i];
    }
  }
  return false;
}

function getParent(node, nodes, edges) {
  const incomers = getIncomers(node, nodes, edges)
  if (incomers.length) {
    return incomers[0]
  }
  return false
}

function getFirstChild(node, nodes, edges) {
  const children = getOutgoers(node, nodes, edges)
  if (children.length) {
    return children[0]
  }
  return false
}

const getNodesWithPosition = (nodes) => nodes.map(node => {
  if (!node.parentNode) {
    return node // already has real position
  }
  const parent = nodes.find(x => x.id == node.parentNode)
  if (!parent) {
    console.log("error")
    return node
  }
  const newNode = { // add the parents position
    ...node,
    position: {
      x: parent.position.x + node.position.x,
      y: parent.position.y + node.position.y
    }
  }
  return newNode
})

function constructNewNode(nodeType, argType) {
  const newID = uuidv4();
  let newNode = {
    id: newID,
    type: nodeType,
    width: defaultNodeWidth,
    height: defaultNodeHeight,
    numDsWODs: 1,
    data: {
      number: '',
      nodeType: nodeType,
      argType: argType,
      id: newID,
      text: "",
      rows: defaultTextareaRows,
      cols: defaultTextareaCols,
    },

    // this is the default position which gets updated by autoLayout.js
    position: { x: 0, y: 0 },
  };

  if (argType === "inference-objection" || argType === "inference-reason") {
    newNode.draggable = false;
  }

  return newNode;
}

function constructNewEdge(sourceNode, targetNode) {
  let newEdge = {
    id: "e" + sourceNode.id + "-" + targetNode.id,
    type: "customEdge",
    source: sourceNode.id,
    target: targetNode.id,
    data: {
      argType: targetNode.data.argType
    },
  };
  return newEdge;
}

function getDeletionFallout(node, nodes, edges) {
  let nodesToDelete = [node]
  let newSelection;

  // function to add all children to nodesToDelete
  const recurse = n => {
    const newNodes = getOutgoers(n, nodes, edges)
    nodesToDelete = nodesToDelete.concat(newNodes)
    newNodes.forEach(n => recurse(n, nodes, edges))
  }

  if ((node.data.argType === "reason-group") || (node.data.argType === "objection-group")) {
    // console.log("delete group")
    // reason groups must delete the branch starting at their inference
    const inference = getIncomers(node, nodes, edges)[0]
    nodesToDelete.push(inference)
    recurse(inference, nodes, edges)
    // select the next parent
    newSelection = getIncomers(inference, nodes, edges)[0]
  } else if ((node.data.argType == "reason") || (node.data.argType == "objection")) {
    // it is a copremise reason or objection, check if it is the only child
    // console.log("delete reason")
    const parent = getIncomers(node, nodes, edges)[0]
    const siblings = getOutgoers(parent, nodes, edges)
    if (siblings.length <= 1) {
      // its the only child, so delete the whole branch
      const inference = getIncomers(parent, nodes, edges)[0]
      nodesToDelete.push(inference)
      recurse(inference, nodes, edges)
      // select the next parent
      newSelection = getIncomers(inference, nodes, edges)[0]
    } else {
      // it has siblings, so just delete its own descendants
      recurse(node, nodes, edges)
      newSelection = parent
    }
  } else {
    // its an inference, delete the branch
    // console.log("delete inference")
    recurse(node, nodes, edges)
    // select the next parent
    newSelection = getIncomers(node, nodes, edges)[0]
  }


  const edgesToDelete = getConnectedEdges(nodesToDelete, edges)

  return [nodesToDelete, edgesToDelete, newSelection]
}

const handleSelectionChange = (nodes, edges, selected)=>{
  // set .data.highlight on the edges leading to the currently selected node

  if(!selected || !nodes || !edges || selected.data.argType == "main-contention"){
    // remove .highlight
    for(let edge of edges){
      edge.data = {
        ...edge.data,
        highlight: false
      }
    }
    return
  }

  const type = selected.data.argType

  const parent = getIncomers(selected, nodes, edges)[0]
  const grandparent = parent ? getIncomers(parent, nodes, edges)[0] : undefined
  const greatgrandparent = grandparent ? getIncomers(grandparent, nodes, edges)[0] : undefined

  let edgesToHighlight = []

  if(type.includes("inference")){
    // only highlight one
    if(!parent) return;
    
    const edge = edges.filter(e=>(e.target == selected.id) && (e.source == parent.id))[0]
    edgesToHighlight.push(edge.id)
  }else if(type.includes("group")){
    // highlight two
    if(!parent || !grandparent) return;
    
    const edge1 = edges.filter(e=>(e.target == selected.id) && (e.source == parent.id))[0]
    const edge2 = edges.filter(e=>(e.target == parent.id) && (e.source == grandparent.id))[0]
    edgesToHighlight.push(edge1.id, edge2.id)
  }else{
    // highlight two
    if(!parent || !grandparent || !greatgrandparent) return;

    const edge1 = edges.filter(e=>(e.target == parent.id) && (e.source == grandparent.id))[0]
    const edge2 = edges.filter(e=>(e.target == grandparent.id) && (e.source == greatgrandparent.id))[0]
    edgesToHighlight.push(edge1.id, edge2.id)
  }

  for(let edge of edges){
    edge.data = {
      ...edge.data,
      highlight: edgesToHighlight.includes(edge.id)
    }
  }
  
}

export { handleSelectionChange, getSelectedNode, constructNewNode, constructNewEdge, getDeletionFallout, getParent, getFirstChild, getNodesWithPosition };

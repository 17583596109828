import { keys } from "./Help";
import { useStore } from "../../utils/store";

export const DescribedByTargets = () => {
    const status = useStore(s=>s.status)
    return(
        <div className="visually-hidden">
            <div id="nav-instructions">
                <ul style={{flexDirection:"column", fontFamily:"Poppins", padding:0, margin:0}}>
                    {keys.map((k,i)=>
                        <li 
                            style={{width:'100%', display:"flex", flexDirection:"row"}}
                            key={k.key}
                        >
                            <div style={{marginRight:"auto"}}>{k.action}</div>
                            <div style={{marginLeft:100}}>{k.key}</div>
                        </li>
                    )}
                </ul>
            </div>
            <div id="draggable">
                This box can be moved
            </div>
            <div id="non-draggable">
                This box can not be moved
            </div>
            <div id="dropable">
                You can move here
            </div>
            <div id="non-dropable">
                You cannot move here
            </div>
            {/* <div role="status" aria-live="polite">
                <p>
                    {status}
                </p>
            </div> */}
        </div>
    )
}
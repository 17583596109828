import { useCallback, useEffect, useRef } from "react";
import { useStore } from "../../utils/store"
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";

var alertCB = null;
var confirmCB = null;
var promptCB = null;

export const alert = title => new Promise((res, rej)=>{
    console.log("alert invoked")
    alertCB = res
    useStore.setState(s=>({
        alert: {
            title: title, 
            show: true,
        }
    }))
})

export const confirm = title => new Promise((res, rej)=>{
    confirmCB = res
    useStore.setState(s=>({
        confirm: {
            title: title, 
            show: true
        }
    }))
})

export const prompt = (title, defaultText) => new Promise((res, rej)=>{
    promptCB = res
    useStore.setState(s=>({
        prompt: {
            title: title, 
            show: true,
            text: defaultText || ""
        }
    }))
})

export const Alert = () => {

    const alert = useStore(state=>state.alert)
    const setAlert = useStore(state=>state.setAlert)

    const submit = useCallback(()=>{
        console.log("click alert")
        setAlert({
            title: "",
            show:false
        })
        alertCB && alertCB()
        alertCB = null
    },[alertCB])

    const handleKeyDown = useCallback((event)=>{
        if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
            submit();
        }
    }, [])
    
    useEffect(()=>{
        document.addEventListener("keydown", handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    },[])

    return (
        <Modal 
            show={alert.show} 
            onHide={()=>setAlert(state=>({...state, show:false}))}
            size="sm"
            style={{zIndex:102}}
            centered
            backdrop="static"
            backdropClassName="alert-backdrop"
        >
            <Modal.Body>
                <div className="alert-text">
                    {alert.title}
                </div>
                <div className="alert-buttons">
                    <Button
                        variant="info"
                        aria-label="ok"
                        tabIndex={0}
                        className="alert-button-ok"
                        onClick={submit}
                    >
                        OK
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export const Confirm = () => {

    const confirm = useStore(state=>state.confirm)
    const setConfirm = useStore(state=>state.setConfirm)

    const submit = useCallback(()=>{
        console.log("click ok")
        setConfirm({
            title: "",
            show:false
        })
        confirmCB && confirmCB(true)
        confirmCB = null
    },[confirmCB])

    const cancel = useCallback(()=>{
        console.log("click cancel")
        setConfirm({
            title: "", 
            show:false
        })
        confirmCB && confirmCB(false)
        confirmCB = null
    },[confirmCB])

    const handleKeyDown = useCallback((event)=>{
        if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
            submit();
        } else if(event.key === 'Esc'){
            event.preventDefault()
            event.stopPropagation()
            cancel();
        }
    }, [])

    useEffect(()=>{
        document.addEventListener("keydown", handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    },[])

    return (
        <Modal 
            show={confirm.show} 
            onHide={()=>setConfirm(state=>({...state, show:false}))}
            size="sm" 
            style={{zIndex:102}} 
            backdrop="static"
            centered
            backdropClassName="alert-backdrop"
        >
            <Modal.Body>
                <div className="alert-text">
                    {confirm.title}
                </div>
                <div className="alert-buttons">
                    <Button
                        variant="info"
                        aria-label="ok"
                        tabIndex={0}
                        className="alert-button confirm"
                        onClick={submit}
                    >
                        OK
                    </Button>
                    <Button
                        variant="warning"
                        aria-label="cancel"
                        tabIndex={0}
                        className="alert-button cancel"
                        onClick={cancel}                        
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export const Prompt = () => {

    const prompt = useStore(state=>state.prompt)
    const setPrompt = useStore(state=>state.setPrompt)
    const setPromptText = useStore(state=>state.setPromptText)
    const input_ref = useRef(null)
    const show = prompt.show

    const submit = useCallback(()=>{
        console.log("click ok")
        const text = useStore.getState().prompt.text
        promptCB && promptCB(text)
        setPrompt({
            title: "", 
            show:false,
            text: ""
        })
        promptCB = null
    }, [promptCB])

    const cancel = useCallback(()=>{
        console.log("click cancel")
        promptCB && promptCB(false)
        setPrompt({
            title:"",
            show:false,
            text: ""
        })
        promptCB = null
    })

    useEffect(()=>{
        if(show && input_ref.current){
            setTimeout(()=>input_ref.current.focus(), 200)
        }
    }, [show])

    const handleKeyDown = useCallback((event)=>{
        if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation()
            submit();
        } else if(event.key === 'Esc'){
            event.preventDefault()
            event.stopPropagation()
            cancel();
        }
    }, [])

    useEffect(()=>{
        document.addEventListener("keydown", handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    },[])

    return (
        <Modal 
            show={show} 
            onHide={()=>setPrompt(state=>({...state, show:false}))}
            style={{zIndex:102}} 
            size="sm" 
            backdrop="static"
            backdropClassName="alert-backdrop"
        >
            <Modal.Body>
                <div className="alert-text">
                    {prompt.title}
                </div>
                <div className="alert-input">
                    <input 
                        type="text"
                        value={prompt.text}
                        onChange={e=>setPromptText(e.target.value)}
                        ref={input_ref}
                    />
                </div>
                <div className="alert-buttons">
                    <Button 
                        variant="info" 
                        className="alert-button confirm"
                        tabIndex={0}
                        aria-label="ok"
                        onClick={submit}
                    >
                        OK
                    </Button>
                    <Button
                        variant="warning"
                        className="alert-button cancel"
                        tabIndex={0}
                        aria-label="cancel"
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
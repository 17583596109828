import { getOutgoers, getIncomers, getConnectedEdges } from "react-flow-renderer";
import { defaultNodeWidth, defaultNodeHeight } from "./mapConstants";
import dagre from 'dagre';

/**  returns a multidimensional array of the nodes in the map sorted by depth.
    Each element in the array , is an array of the nodes within that level.
    The first element is an array with one element, the main contention.
    The element after that is an array with all children of the main contention, and so on.
*/

function getLevels(nodes, edges) {
  let levels = [];
  let nodesUntilNextLevel = 1;
  let nodesInNextLevel = 0;
  let level = [];
  let toBeProcessed = [nodes[0]];
  while (toBeProcessed.length > 0) {
    let currentlyProcessing = toBeProcessed.shift();
    level.push(currentlyProcessing);
    // This if block sticks the children of group nodes in the front of toBeProcessed as nodes that should be processed when going through the current level.
    // Note the order doesn't change because we use unshift while going through the for loop from groupChildren.length - 1 and decrementing by 1 each time.
    if (currentlyProcessing.data.argType === "reason-group" || currentlyProcessing.data.argType === "objection-group") {
      let groupChildren = getOutgoers(currentlyProcessing, nodes, edges);
      for (let i = groupChildren.length - 1; i >= 0; i--) {
        toBeProcessed.unshift(groupChildren[i]);
        nodesUntilNextLevel++;
      }
    } else {
      let children = getOutgoers(currentlyProcessing, nodes, edges);
      for (let i = 0; i < children.length; i++) {
        //here toBeProcessed becomes an array of all the children of the currentlyProcessing node
        toBeProcessed.push(children[i]);
        nodesInNextLevel++;
      }
    }

    nodesUntilNextLevel--;

    if (nodesUntilNextLevel <= 0) {
      // This block of code creates an array called arrayOfGroupArraysInLevel each of whose elements is an array that bundles together premises/objections/inferences
      // according to whether they belong together in a co-premise group.
      let arrayOfGroupArraysInLevel = [];
      let groupArray = [];
      for (let i = 0; i < level.length; i++) {
        // group node
        if (level[i].data.argType === "reason-group" || level[i].data.argType === "objection-group") {
          if (groupArray[0]) {
            arrayOfGroupArraysInLevel.push(groupArray);
          }
          groupArray = [];
          groupArray.push(level[i]);
          // premise or objection that belongs to a group node already registered
        } else if (groupArray[0] && level[i].parentNode === groupArray[0].id) {
          groupArray.push(level[i]);
          if (i === level.length - 1) {
            arrayOfGroupArraysInLevel.push(groupArray);
          }
          // inference that doesn't belong to any group.
        } else {
          if (groupArray[0]) {
            arrayOfGroupArraysInLevel.push(groupArray);
          }
          groupArray = [];
          groupArray.push(level[i]);
          arrayOfGroupArraysInLevel.push(groupArray);
          groupArray = [];
        }
      }

      levels.push(level);
      level = [];
      // This next line is what guarantees that the next time the while loop runs, 
      // it will go through all the children pushed into toBeProcessed earlier.
      nodesUntilNextLevel = nodesInNextLevel;
      nodesInNextLevel = 0;
    }

  }

  return levels;
}

const setNumber = (nodes, edges) => {
  // the numbering scheme is a string: {data.rank}.{data.order}.{data.position}
  // only CPG members get position

  // get the root node
  const root = nodes.filter(x => x.data.argType == "main-contention")[0]
  root.data.rank = 1

  // recursively add ranks
  const addRanks = (node, rank) => {
    const children = getOutgoers(node, nodes, edges)
    children.sort((a, b) => a.position.x - b.position.x)
    children.forEach((n, i) => {
      if (node.type == "copremise") {
        // CPG members don't incriment rank
        n.data.rank = rank
        // position is the 3rd part of the numbering scheme
        n.data.position = i
        addRanks(n, rank)
      } else {
        // others do incriment rank
        n.data.rank = rank + 1
        addRanks(n, rank + 1)
      }
    })
  }

  addRanks(root, 1)

  // sort into rows, sort the row, assign order, pass to CPG members
  const ranks = {}
  nodes.forEach(n => {
    const rank = n.data.rank
    if (!ranks[rank]) {
      // make an array for the row if it doesnt exist yet
      ranks[rank] = []
    }
    ranks[rank].push(n)
  })

  Object.keys(ranks).forEach(rank => {
    const row = ranks[rank]
    // remove the children and sort by position
    const groups = row.filter(x => !x.parentNode).sort((a, b) => a.position.x - b.position.x)
    groups.forEach((n, i) => {
      n.data.order = i
      // order the CPG members and assign order of parent
      if (n.type == "copremise") {
        const children = getOutgoers(n, nodes, edges)
        children.forEach(c => {
          c.data.order = n.data.order
        })
      }
    })
  })
}

const dim = { width: defaultNodeWidth, height: defaultNodeHeight }
const CPGMargin = 26
// const CPGMargin = 35
// const CPGMargin = 30

const getHighest = nodes => nodes.reduce((acc, cur) => Math.max(acc, cur.data.height || dim.height), 0)

function layoutWithDagre(nodes, edges) {
  const g = new dagre.graphlib.Graph({ compound: true })

  // const rankdir = "BT" // horizontal or vertical layout
  const rankdir = "TB" // horizontal or vertical layout
  const marginx = 100  // horizontal padding of graph
  const marginy = 100  // vertical padding of graph
  const nodesep = 30  // horizontal spacing between nodes
  // const nodesep = 50  // horizontal spacing between nodes
  const ranksep = 70  // vertical spacing between ranks

  g.setGraph({
    rankdir: rankdir, // horizontal or vertical layout
    marginx: marginx, // horizontal padding of graph
    marginy: marginy, // vertical padding of graph
    nodesep: nodesep, // horizontal spacing between nodes
    ranksep: ranksep, // vertical spacing between ranks
  })

  g.setDefaultEdgeLabel(() => ({}))

  nodes.forEach(n => g.setNode(n.id, { width: dim.width, height: n.data.height || n.height || dim.height }))

  nodes.forEach(n => {
    if (n.data.argType !== "main-contention" && n.type !== "copremise") {
      // CPG memeber and inferences
      if (getIncomers(n, nodes, edges)[0].type === "copremise") {
        // GPG members
        const parent = getIncomers(n, nodes, edges)[0]
        g.setParent(n.id, parent.id)

        // we also want to set an edge connecting each premise in a copremise group to the parent of the copremise group node.
        const inf = getIncomers(parent, nodes, edges)[0]
        g.setEdge(inf.id, n.id)

      } else {
        // inference
        const parent = getIncomers(n, nodes, edges)[0]
        g.setEdge(parent.id, n.id)
      }
    }
  })

  dagre.layout(g);

  nodes.forEach(n => {

    // CPG || INFERENCE || MAIN CONTENTION
    if (!n.parentNode) {
      n.position.x = g.node(n.id).x - (n.width / 2);
      n.position.y = g.node(n.id).y - ((n.data.height || n.height || dim.height) / 2);
      // main contention needs a bump
      if (n.data.argType == "main-contention") {
        // n.position.y -= 20
        n.data = { ...n.data } // force them to rerender
      }
    }

    // then reposition the copremise nodes and center their inference
    if (n.type === "copremise") {
      const descendants = getOutgoers(n, nodes, edges)
      let numPremises = descendants.length
      const height = getHighest(descendants) + CPGMargin * 3 - 8
      const width = numPremises * (dim.width + (nodesep / 2))

      n.position.x = g.node(n.id).x - (width / 2)
      n.position.y = g.node(n.id).y - (height / 2) + 30
      n.height = height
      n.width = width
      n.data.height = height
      n.data.width = width
      n.style.height = height
      n.style.width = width
      n.data = { ...n.data } // force them to rerender
    }
  })

  // center inferences
  nodes.forEach(n => {
    if (n.data.argType !== "main-contention" && n.type !== "copremise" && !n.parentNode) {
      const children = getOutgoers(n, nodes, edges)
      const left = children.reduce((acc, cur) => Math.min(acc, cur.position.x), Infinity)
      const right = children.reduce((acc, cur) => Math.max(acc, cur.position.x + (cur.data.width || cur.width)), 0)
      const center = left + ((right - left) / 2)
      n.position.x = center - (n.width / 2)
      n.position.y += 12
    }
  })

  // snuggle CPG members
  let arrayOfGroupArrays = [];
  let groupArray = [];
  for (let i = 0; i < nodes.length; i++) {
    // CPG
    if (nodes[i].type === "copremise") {
      if (groupArray[0]) {
        arrayOfGroupArrays.push(groupArray);
      }
      groupArray = [];
      groupArray.push(nodes[i]);
      // this covers the case where we find a premise or objection that belongs to a group node already registered
    } else if (groupArray[0] && nodes[i].parentNode === groupArray[0].id) {
      groupArray.push(nodes[i]);
      if (i === nodes.length - 1) {
        arrayOfGroupArrays.push(groupArray);
      }
    } else {
      // inference
      if (groupArray[0]) {
        arrayOfGroupArrays.push(groupArray);
      }
      groupArray = [];
    }
  }

  // ...and then loop through each group array, fixing the position of the nodes to snuggle them together
  for (let i = 0; i < arrayOfGroupArrays.length; i++) {
    for (let j = 1; j < arrayOfGroupArrays[i].length; j++) {
      arrayOfGroupArrays[i][j].position.x = (nodesep / 4) + (j - 1) * (dim.width + (nodesep / 2))
      // arrayOfGroupArrays[i][j].position.y = 26
      arrayOfGroupArrays[i][j].position.y = CPGMargin - 3
    }
  }

  setNumber(nodes, edges)

  // force every node to rerender
  // setNodes(nodes=>nodes.map(n=>({
  //   ...n,
  //   data: {...n.data}
  // })))

  // finally, reposition all the nodes so that the main contention is in the center at the top, and everything else is repositioned accordingly.
  let mainContention = {}
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].data.argType === "main-contention") {
      mainContention = nodes[i]
    }
  }

  let destinationXPositionForMainContention = (window.innerWidth / 2) - (defaultNodeWidth / 2)
  let destinationYPositionForMainContention = 100;
  let horizontalOffset = destinationXPositionForMainContention - mainContention.position.x;
  let verticalOffset = destinationYPositionForMainContention - mainContention.position.y;

  for (let i = 0; i < nodes.length; i++) {
    if (!nodes[i].parentNode) {
      nodes[i].position.x += horizontalOffset;
      nodes[i].position.y += verticalOffset;
    }
  }

}

function applyTabIndex(nodes){
  let ns = [...nodes]
  ns.sort((a,b)=>a.data?.number > b.data?.number ? 1 : -1)
  ns.forEach((n,i)=>{
    let src = nodes.find(x=>x.id == n.id)
    if(!src || !src.data){
      return
    }
    src.data.tabIndex = i + 1
  })
}

const sortNodes = (setNodes) => {
  const sortByPosition = (a,b) => {
    console.log({a,b})
  }
  setNodes(nodes=>nodes.sort(sortByPosition))
}

function autoLayout(nodes, edges, setNodes, setEdges) {
  let levels = getLevels(nodes, edges);
  let orderedNodes = [];
  for (let i = 0; i < levels.length; i++) {
    for (let j = 0; j < levels[i].length; j++) {
      orderedNodes.push(levels[i][j])
    }
  }
  layoutWithDagre(orderedNodes, edges );

  // force edges to rerender
  setEdges([...edges])
  
  // applyTabIndex(orderedNodes)
}

export { autoLayout };
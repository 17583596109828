import { BlocksShuffleTwo } from "react-svg-spinners";

const Loading = () =>
    <div data-norender style={{position:"absolute", top:0, bottom:0, left:0, right:0, backgroundColor:"white", display:"flex", zIndex:100, opacity:0.9, alignItems:'center', justifyContent:"center", flexDirection:'column'}}>
        <div style={{position:"absolute", opacity:0.8}}>
            <BlocksShuffleTwo width={220} height={220} color="#F0CA38"/>
        </div>
        <div style={{position:"absolute", zIndex:2}}>
            <Logo size={0.5}/>
        </div>
    </div>

export const Logo = ({size}) =>
    <svg width={`${311 * size}`} height={`${377 * size}`} viewBox="0 0 311 377" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M133.688 0L154.892 50.8014L48.1277 307.482H0L133.688 0Z" fill="#EEC500"/>
        <path d="M177.805 0H133.688L262.028 307.482H310.156L177.805 0Z" fill="#EEC500"/>
        <path d="M101.696 179.142H208.46L222.968 213.901H87.1878L101.696 179.142Z" fill="#EEC500"/>
        <path d="M133.688 69.5177L154.892 120.319L101.603 248.66L48.1277 377H0L133.688 69.5177Z" fill="#02A0C6"/>
        <path d="M177.805 69.5177H133.688L262.028 377H310.156L177.805 69.5177Z" fill="#02A0C6"/>
        <path d="M101.603 248.66H208.46L222.968 283.418H87.12L101.603 248.66Z" fill="#02A0C6"/>
        <path d="M133.688 34.7589L154.892 85.5603L48.1277 342.241H0L133.688 34.7589Z" fill="#B70101" fillOpacity="0.6"/>
        <path d="M177.805 34.7589H133.688L262.028 342.241H310.156L177.805 34.7589Z" fill="#B70101" fillOpacity="0.6"/>
        <path d="M101.696 213.901H208.46L222.968 248.66H87.1878L101.696 213.901Z" fill="#B70101" fillOpacity="0.6"/>
        <path d="M147.057 69.5177H177.805L295.188 342.241H262.028L147.057 69.5177Z" fill="#082103"/>
        <path d="M154.511 87.1998L147.057 69.5177H133.688L15.0991 342.241H48.1277L154.511 87.1998Z" fill="#082103"/>
        <path d="M101.603 213.901H116.054L101.603 248.66H86.8972L101.603 213.901Z" fill="#082103"/>
        <path d="M208.553 248.66H224.596L209.853 213.901H193.848L208.553 248.66Z" fill="#082103"/>
        <path d="M155.078 49.4645L162.713 69.5177H147.057L155.078 49.4645Z" fill="#BA5C41"/>
        <path d="M116.143 213.732H101.671L116.143 179.223H130.243L116.143 213.732Z" fill="#137000" stroke="#137000" strokeWidth="0.37"/>
        <path d="M194.066 213.732H207.795L193.324 179.223H179.595L194.066 213.732Z" fill="#137000" stroke="#137000" strokeWidth="0.4"/>
    </svg>

export default Loading